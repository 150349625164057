import _ from 'lodash';

import { Button, TextWithIcon } from '@/components/atomic/nuclei';
import { NOTIFICATION_TYPE } from '@/config/common';

const getButtonPairOptions = ({ backward, forward, type }) => {
  const defaultOnClickAction = () => true;

  let defaultBackward = {
    className:
      'py-3 px-8 text-sm font-medium self-end rounded-lg bg-gray text-black',
    name: 'Previous',
    onClick: defaultOnClickAction,
    show: true
  };

  let defaultForward = {
    className:
      'py-3 px-8 text-sm font-medium self-end rounded-lg bg-brand-gradient text-white',
    name: 'Next',
    onClick: defaultOnClickAction,
    show: true
  };

  switch (type) {
    case 'viewCTAPair':
      defaultBackward = { ...defaultBackward, ...backward, name: 'Close' };
      defaultForward = { ...defaultForward, ...forward, name: 'Edit' };
      break;
    case 'editCTAPair':
      defaultBackward = { ...defaultBackward, ...backward, name: 'Cancel' };
      defaultForward = { ...defaultForward, ...forward, name: 'Save' };
      break;
    case 'addNewCartAndViewPlannerBoard':
      defaultBackward = {
        ...defaultForward,
        ...forward,
        name: 'Import Cart'
      };
      defaultForward = {
        ...defaultBackward,
        ...backward,
        name: 'Add New Cart'
      };
      break;
    default:
      break;
  }
  return {
    forward: { ...defaultForward },
    backward: { ...defaultBackward }
  };
};

const notificationConfigEnum = {
  [NOTIFICATION_TYPE.ERROR]: {
    color: 'text-red-500',
    icon: 'error-fill-red-icon.svg'
  },
  [NOTIFICATION_TYPE.SUCCESS]: {
    color: 'text-green',
    icon: 'check-fill-green-icon.svg'
  }
};

const Notification = ({
  notificationConfig: {
    errors,
    toastConfig: { message, show: showToast, successToast: toastStatus }
  }
}) => {
  const formHasNoErrors = _.isEmpty(errors);

  const notificationType =
    formHasNoErrors && toastStatus
      ? NOTIFICATION_TYPE.SUCCESS
      : NOTIFICATION_TYPE.ERROR;

  const messageToDisplay = formHasNoErrors
    ? message
    : 'Unable to save. Please review form for errors';

  const { color, icon } = notificationConfigEnum[notificationType];

  return (
    <TextWithIcon
      {...{
        className: 'flex gap-2 items-center md:ml-[29rem] pl-10 pt-5',
        icon,
        label: messageToDisplay,
        labelStyle: `${color} mt-1 font-medium`,
        show: Boolean(showToast)
      }}
    />
  );
};

const ViewEditCTAPair = ({
  backward,
  containerClass = 'justify-end border-t border-brand bg-white fixed bottom-0 h-20 w-full left-0 px-10 z-[2]',
  forward,
  notificationConfig,
  type
}) => {
  const options = getButtonPairOptions({
    backward,
    forward,
    type
  });

  const buttonOptions = [options.backward, options.forward];

  return (
    <div
      className={`flex ${containerClass} ${notificationConfig?.toastConfig.show ? '!justify-between' : ''}`}
    >
      {notificationConfig && <Notification {...{ notificationConfig }} />}
      <div className='flex justify-end items-center gap-3'>
        {buttonOptions.map(
          ({ className, disabled = false, name, onClick, show = true }) =>
            show && (
              <div key={name}>
                <Button
                  {...{
                    className,
                    disabled,
                    label: name,
                    onClick
                  }}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default ViewEditCTAPair;
