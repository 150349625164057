import Image from 'next/image';

import { CloseIcon, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { checkStepHasErrors } from '@/services/hostEvent.service';

const StepFormHeader = ({
  additionalOperationOnTabClick = () => {},
  errors,
  fieldsErrorConfig,
  formActiveStepId,
  onExitClick,
  setFormActiveStepId,
  showCloseIcon,
  tabList
}) => {
  const handleClick = (id) => {
    // TODO: commented as might be useful in feature.
    // document.getElementById(id).scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start'
    // });
    setFormActiveStepId(id);
    additionalOperationOnTabClick();
  };

  return (
    <div className='sticky top-0 pr-2 bg-white z-10'>
      <div className='flex gap-6 pt-4 pb-8 relative'>
        {tabList.map(({ icon, id, label }, index) => {
          const { alt, name, style } = icon || {};
          const isActiveStep = formActiveStepId === id;
          const stepHasErrors = checkStepHasErrors({
            fieldsErrorConfig,
            errors,
            stepId: id
          });
          return (
            <div
              className='cursor-pointer'
              onClick={() => handleClick(id)}
              key={index}
            >
              <div className='flex flex-row gap-3 items-center'>
                {icon && (
                  <Image
                    {...{
                      alt,
                      height: 0,
                      src: `${staticMediaStoreBaseURL}/icons/${name}`,
                      style,
                      width: 0
                    }}
                  />
                )}
                <Text
                  {...{
                    className: `text-xl font-semibold ${isActiveStep ? 'text-nero' : 'text-dim-gray'}`,
                    content: label
                  }}
                />
                {stepHasErrors && (
                  <Image
                    {...{
                      alt: 'error',
                      height: 0,
                      src: `${staticMediaStoreBaseURL}/icons/error-fill-red-icon.svg`,
                      style: { height: 16, width: 16, marginLeft: 10 },
                      width: 0
                    }}
                  />
                )}
              </div>
              <div
                className={`${isActiveStep ? 'bg-brand-gradient' : 'bg-neutral'} h-2 rounded-full mt-3 w-48`}
              />
            </div>
          );
        })}
        <div className='w-10 h-10 absolute left-[97%] top-2'>
          {showCloseIcon && (
            <CloseIcon
              {...{
                height: 60,
                icon: 'close-red-icon.svg',
                onClick: onExitClick,
                width: 60
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StepFormHeader;
