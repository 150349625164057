import Image from 'next/image';
import { PropTypes } from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

import {
  Button,
  CloseIcon,
  Text,
  TextKeyValuePair
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const PDFDownloadConfirmationModal = ({
  onClickCopyQuoteLink,
  onClickDownloadQuotationPDF,
  togglePDFConfirmationModal
}) => (
  <Modal
    isOpen={true}
    toggle={togglePDFConfirmationModal}
  >
    <ModalBody
      className='z-120 bg-white m-18 w-full md:w-41
    min-h-96 mx-auto px-8 md:rounded-lg pb-2 pt-4'
    >
      <div className='flex-1 flex flex-row-reverse relative left-4'>
        <CloseIcon {...{ onClick: togglePDFConfirmationModal }} />
      </div>
      <div className='flex flex-col gap-7'>
        <Text
          {...{
            className: 'text-center text-xl font-semibold pb-5',
            content: 'Step into the Future: Say Goodbye to PDFs!'
          }}
        />
        <Image
          alt='superhero'
          className='mx-auto'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/superhero.svg`}
          style={{ height: 261, width: 462 }}
          width={0}
        />
        <TextKeyValuePair
          {...{
            className: 'flex flex-col gap-3 text-center',
            label: 'Still downloading PDFs?',
            labelClass: 'text-nero font-semibold',
            value:
              'Ditch the Stone Age and enjoy the dynamic, interactive experience of real-time quoting',
            valueClassName: 'text-dim-gray font-semibold'
          }}
        />
      </div>
      <div className='flex flex-col gap-4 my-4'>
        <Button
          {...{
            className:
              'bg-brand-gradient text-white h-12.5 py-4 rounded-md text-sm font-medium leading-5 w-full md:mb-0',
            label: 'Step into the Future! Get Web Quote link',
            onClick: onClickCopyQuoteLink
          }}
        />
        <Text
          {...{
            className: 'text-dim-gray text-sm font-medium text-center',
            content: 'Anyways, I prefer PDF!'
          }}
        />
        <div className='flex gap-6'>
          <Button
            {...{
              className:
                'bg-white border border-brand text-brand h-12.5 py-4 rounded-md text-sm font-medium leading-5 w-full md:mb-0',
              iconPosition: 'Left',
              iconURL: `${staticMediaStoreBaseURL}/icons/download-red-with-frame.svg`,
              label: 'Quote',
              onClick: () => onClickDownloadQuotationPDF({ isProforma: false })
            }}
          />
          <Button
            {...{
              className:
                'bg-white border border-brand text-brand h-12.5 py-4 rounded-md text-sm font-medium leading-5 w-full md:mb-0',
              iconPosition: 'Left',
              iconURL: `${staticMediaStoreBaseURL}/icons/download-red-with-frame.svg`,
              label: 'Proforma Invoice',
              onClick: () => onClickDownloadQuotationPDF({ isProforma: true })
            }}
          />
        </div>
      </div>
    </ModalBody>
  </Modal>
);

export default PDFDownloadConfirmationModal;

PDFDownloadConfirmationModal.propTypes = {
  onClickCopyQuoteLink: PropTypes.func,
  onClickDownloadQuotationPDF: PropTypes.func,
  setShowPDFDownloadConfirmationModal: PropTypes.func,
  showPDFDownloadConfirmationModal: PropTypes.bool
};
