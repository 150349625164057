import Image from 'next/image';

import { Text, TextWithIcon, Tooltip } from '@/components/atomic/nuclei';
import {
  CART_STATUS,
  mediaStoreBaseURL,
  staticMediaStoreBaseURL
} from '@/config/common';
import { handleOnClickZendesk } from '@/helpers/zendeskTicket';
import { convertToShortMonthDateFormat, formatTimeToAmPm } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';

const UserEventAddress = ({
  alt,
  className,
  icon,
  iconClassName = '',
  id,
  label,
  onClick = () => {}
}) => (
  <div
    className={`flex gap-3 ${iconClassName}`}
    id={id}
  >
    <div className='w-5 h-5'>
      <Image
        alt={alt}
        height={0}
        onClick={onClick}
        src={`${staticMediaStoreBaseURL}/icons/${icon}`}
        style={{ height: 20, width: 20 }}
        width={0}
      />
    </div>
    <Text {...{ content: label, className }} />
  </div>
);

const UserEventInfo = ({
  eventContactName,
  eventDate,
  eventStartTime,
  zendeskTicketId
}) => {
  const zendeskTicketIdLabel = zendeskTicketId || 'N/A';
  const eventDateAndTime = `${convertToShortMonthDateFormat(
    eventDate
  )}, ${formatTimeToAmPm(eventStartTime)}`;

  const cartUserEventInfo = [
    {
      alt: 'zendesk',
      icon: 'zendesk-icon.svg',
      iconClassName: 'cursor-pointer',
      id: 'zendesk-ticket-number',
      label: parseNumberedLabel(zendeskTicketIdLabel),
      onClick: () => zendeskTicketId && handleOnClickZendesk(zendeskTicketId)
    },
    {
      alt: 'user',
      icon: 'user-red.svg',
      id: 'host-name',
      label: eventContactName
    },
    {
      alt: 'calender',
      icon: 'calender-red.svg',
      id: 'event-date-time',
      label: eventDateAndTime
    }
  ];

  return cartUserEventInfo.map(
    ({ alt, icon, iconClassName, id, label, onClick }, key) => (
      <UserEventAddress
        key={key}
        {...{
          alt,
          icon,
          iconClassName,
          id,
          label,
          onClick
        }}
      />
    )
  );
};

const CartInfo = ({ cartNumber, status }) => (
  <>
    <Text
      {...{
        className: `py-1 px-2 rounded text-sm font-medium ${
          status === CART_STATUS.DRAFT.value
            ? 'bg-gray text-dim-gray'
            : 'bg-greenlight text-green-base'
        }`,
        content: status,
        id: 'cart-status'
      }}
    />

    <TextWithIcon
      {...{
        className: 'py-1 px-2 border border-neutral rounded',
        icon: 'user-event-brand-icon.svg',
        id: 'cart-number',
        label: parseNumberedLabel(cartNumber),
        labelStyle: 'text-dim-gray text-sm font-medium'
      }}
    />
  </>
);

const TenderCartUserEventInfo = ({
  cart,
  userEventDetail,
  zendeskTicketId
}) => {
  const {
    checkoutEvent: { iconUrl, event: { name: eventName } = {} },
    eventAddress: { formattedAddress },
    eventContactName,
    eventDate,
    eventStartTime
  } = userEventDetail;
  const { cartNumber, status } = cart;

  const tooltipConfigList = [
    {
      target: 'zendesk-ticket-number',
      text: 'Zendesk Ticket Number'
    },
    {
      target: 'host-name',
      text: 'Host Name'
    },
    {
      target: 'event-date-time',
      text: 'Event Date & Time'
    },
    {
      target: 'cart-status',
      text: 'Cart Status'
    },
    {
      target: 'cart-number',
      text: 'Cart Number'
    },
    {
      target: 'event-location',
      text: 'Event Location'
    }
  ];

  return (
    <div className='flex shadow-card rounded-2.5 h-36'>
      <Image
        alt='event image'
        className='rounded-l-2.5'
        height={0}
        priority={true}
        src={`${mediaStoreBaseURL}/${iconUrl}`}
        style={{ height: 144, width: 144 }}
        unoptimized={true}
        width={0}
      />
      <div className='flex flex-col gap-4 pl-6 py-5'>
        <Text
          {...{
            className: 'text-xl font-medium',
            content: eventName || 'N/A'
          }}
        />
        <div className='flex gap-8 text-base font-medium text-dim-gray'>
          <UserEventInfo
            {...{
              eventContactName,
              eventDate,
              eventStartTime,
              zendeskTicketId
            }}
          />
        </div>
        <UserEventAddress
          {...{
            className: 'text-base font-medium text-dim-gray',
            icon: 'location-icon.svg',
            id: 'event-location',
            label: formattedAddress
          }}
        />
      </div>
      <div className='flex justify-end flex-1 gap-4 self-baseline font-medium py-5 px-6'>
        <CartInfo {...{ cartNumber, status }} />
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

export default TenderCartUserEventInfo;
