export const PRICE_SUMMARY_HEADERS = [
  {
    content: 'DESCRIPTION',
    className: 'w-108 self-center'
  },
  {
    content: 'HAFLA PRICE',
    className: 'w-40 self-center'
  },
  {
    content: 'PARTNER PRICE',
    className: 'w-40 self-center'
  },
  {
    content: 'ABSOLUTE MARGIN (AED)',
    className: 'w-56 self-center'
  },
  {
    content: 'MARGIN %',
    className: 'w-40 self-center'
  }
];

export const CART_ITEM_LIST_HEADERS = [
  {
    label: 'PRODUCT NAME',
    style: 'flex-1'
  },
  {
    label: 'WEB PRICE',
    style: 'w-40'
  },
  {
    label: 'QUANTITY',
    style: 'w-20'
  },
  {
    label: 'UNIT SELLING PRICE',
    style: 'w-24'
  },
  {
    label: 'ITEM TOTAL',
    style: 'w-30'
  },
  {
    label: 'PARTNER',
    style: 'w-40'
  },
  {
    label: 'MARGIN %',
    style: 'w-20'
  },
  {
    label: '',
    style: 'w-40'
  }
];

export const VIEW_EDIT_MODE = {
  EDIT: 'EDIT',
  VIEW: 'VIEW'
};

export const termsAndConditions = [
  'Items are subject to availability upon confirmation.',
  `All work permits, permissions and passes have
      to be arranged and provided by the client prior to the installation date to avoid any delay.`,
  'The cost of replacement/repair is payable by the client in case of any loss/damage.'
];
