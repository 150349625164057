import Image from 'next/image';
import { PropTypes } from 'prop-types';

import { Text, Tooltip } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const EventOrderDetails = ({ userEventDetails }) => {
  const {
    userEventOrderDetailsBanner: { eventName },
    userEventOrderDetailsBody
  } = userEventDetails;
  const tooltipConfigList = [
    {
      target: 'trn-number',
      text: 'TRN Number'
    },
    {
      target: 'delivery-date-time',
      text: 'Delivery Date & Time'
    },
    {
      target: 'host-credit-entity-name',
      text: 'Credit Entity Name'
    },
    {
      target: 'event-location',
      text: 'Event Location'
    },
    {
      target: 'host-name',
      text: 'Host Name'
    },
    {
      target: 'host-phone-number',
      text: 'Host Phone Number'
    },
    {
      target: 'event-date-time',
      text: 'Event Date & Time'
    },
    {
      target: 'pickup-date-time',
      text: 'Pickup Date & Time'
    },
    {
      target: 'event-name',
      text: 'Event Name'
    }
  ];
  return (
    <div className='flex flex-col gap-3 bg-lightgray border-b border-r rounded-b-lg border-l border-platinum text-rich-black md:p-6 p-4'>
      <div
        className='flex-col md:w-2/4'
        id={eventName.id}
      >
        <Text
          {...{
            className: 'text-base md:text-xl font-medium text-rich-black',
            content: eventName.value
          }}
        />
      </div>
      <div className='flex flex-col md:flex-row justify-between'>
        <div className='grid grid-cols-1 md:grid-cols-4 md:grid-rows-2 gap-2 mt-2 md:mt-0'>
          {userEventOrderDetailsBody.map(
            ({ alt, className, icon, id = '', sortOrder, value }) => (
              <div
                className={`flex gap-3 md:gap-2 md:w-96 ${className}`}
                id={id}
                key={sortOrder}
              >
                <div className='flex'>
                  <Image
                    alt={alt}
                    height={0}
                    src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                    style={{ height: 20, width: 20 }}
                    width={0}
                  />
                </div>
                <Text
                  {...{
                    className:
                      'flex self-center text-sm md:text-base font-light max-w-52 truncate',
                    content: value
                  }}
                />
              </div>
            )
          )}
        </div>
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

export default EventOrderDetails;

EventOrderDetails.propTypes = {
  userEventDetails: PropTypes.object.isRequired
};
