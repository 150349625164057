// TODO: Faizan - refactor mux condition for ActionModal & ImageCarousel

import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';

import { ImageCarouselModal } from '@/components/atomic/nuclei';
import { mediaStoreBaseURL, staticMediaStoreBaseURL } from '@/config/common';
import { getRankedImages, itemImageDefault } from '@/helpers/carousel';
import { useIsMobile } from '@/lib/screenResolution';

const parseProductMedia = (productMediaRaw) => {
  if (productMediaRaw.length === 0) return [itemImageDefault];
  return productMediaRaw.map(({ media: { id, source, url }, sortOrder }) => ({
    id,
    original: `${mediaStoreBaseURL}/${url}`,
    sortOrder,
    source,
    thumbnail: `${mediaStoreBaseURL}/${url}`,
    url
  }));
};

const FullScreenButton = ({
  isFullscreen,
  isMobile,
  onClick,
  setViewImageInFullScreen
}) => (
  <div className='absolute top-2 left-2'>
    <Image
      {...{
        alt: 'open product image',
        className: 'cursor-pointer',
        height: 0,
        isFullscreen: setViewImageInFullScreen(isFullscreen),
        onClick,
        src: `${staticMediaStoreBaseURL}/icons/resize-icon.svg`,
        style: {
          height: isMobile ? 24 : 32,
          width: isMobile ? 24 : 32
        },
        width: 0
      }}
    />
  </div>
);

const RenderNavButton = ({
  className,
  imageProps: { imgAlt, imgSrc },
  isMobile,
  onClick
}) => (
  <div className={className}>
    <Image
      {...{
        alt: imgAlt,
        className: 'cursor-pointer',
        height: 0,
        onClick,
        src: `${staticMediaStoreBaseURL}/icons/${imgSrc}`,
        style: {
          height: isMobile ? 24 : 40,
          width: isMobile ? 24 : 40
        },
        width: 0
      }}
    />
  </div>
);

const PDPProductImageSlider = ({ product: { productMedia = [] } }) => {
  const [isMobile] = useIsMobile();
  const imageSliderRef = useRef();

  const [viewImageInFullScreen, setViewImageInFullScreen] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageCarouselModalImage, setImageCarouselModalImage] = useState(false);

  const imageList = getRankedImages(parseProductMedia(productMedia));
  const isProductMediaFound = productMedia.length > 0;

  useEffect(() => {
    imageSliderRef?.current.exitFullScreen();
  }, []);

  return (
    <>
      <div
        className={`mt-3 ${
          viewImageInFullScreen
            ? 'banner-overlay-thumbnail-full-screen'
            : 'banner-overlay-thumbnail'
        }`}
      >
        <ImageGallery
          items={imageList}
          onClick={() => {
            setImageCarouselModalImage(imageList[currentIndex]);
          }}
          onSlide={(currentIndexOfImage) =>
            setCurrentIndex(currentIndexOfImage)
          }
          ref={imageSliderRef}
          renderFullscreenButton={(onClick, isFullscreen) =>
            isProductMediaFound && (
              <FullScreenButton
                {...{
                  isFullscreen,
                  isMobile,
                  onClick: () =>
                    setImageCarouselModalImage(imageList[currentIndex]),
                  setViewImageInFullScreen
                }}
              />
            )
          }
          renderRightNav={(onClick) => (
            <RenderNavButton
              {...{
                className: 'absolute top-2/4 right-5 z-10',
                imageProps: {
                  imgAlt: 'right nav',
                  imgSrc: 'right-nav-carousel.svg'
                },
                isMobile,
                onClick
              }}
            />
          )}
          renderLeftNav={(onClick) => (
            <RenderNavButton
              {...{
                className: 'rotate-180 absolute top-2/4 left-5 z-10',
                imageProps: {
                  imgAlt: 'left nav',
                  imgSrc: 'left-nav-carousel.svg'
                },
                isMobile,
                onClick
              }}
            />
          )}
          showBullets={true}
          showNav={viewImageInFullScreen}
          showPlayButton={false}
          slideOnThumbnailOver
          thumbnailPosition={isMobile ? 'bottom' : 'right'}
          useBrowserFullscreen={!isMobile}
        />
      </div>
      {imageCarouselModalImage && (
        <ImageCarouselModal
          {...{
            images: imageList,
            modalImage: imageCarouselModalImage,
            openImageModal: setImageCarouselModalImage
          }}
        />
      )}
    </>
  );
};

export default PDPProductImageSlider;
