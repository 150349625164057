import { useForm } from 'react-hook-form';

import {
  ProgressBar,
  TextKeyValuePair,
  TextWithIcon,
  ToggleSwitch
} from '@/components/atomic/nuclei';
import {
  convertToCurrencyFormat,
  convertToLowerDenomination,
  parseFormatPriceNumberValueFromAPI
} from '@/lib/numberStringUtils';

const HostCreditEntityCreditUtilization = ({
  creditFacilityInformation,
  creditUtilizationStyle
}) => {
  const { approvedCreditLimit, creditBalance, outstandingPayment } =
    creditFacilityInformation;
  const creditUtilizationAmount = [
    {
      label: convertToCurrencyFormat({
        value: parseFormatPriceNumberValueFromAPI(outstandingPayment)
      }),
      value: 'Outstanding Payment'
    },
    {
      label: convertToCurrencyFormat({
        value: parseFormatPriceNumberValueFromAPI(approvedCreditLimit)
      }),
      value: 'Credit Limit'
    }
  ];

  const { register } = useForm({ values: creditFacilityInformation });

  return (
    <div className='flex flex-col my-4 rounded-lg w-full'>
      <div className='bg-white border rounded-t-lg flex justify-between gap-2 items-center py-2 px-3'>
        <TextWithIcon
          {...{
            className: 'shadow-none',
            icon: 'credit-amount-used.svg',
            iconHeight: 24,
            iconWidth: 24,
            label: `Credit Balance: ${convertToCurrencyFormat({ value: parseFormatPriceNumberValueFromAPI(creditBalance) })}`,
            labelStyle: 'font-semibold text-base text-brand'
          }}
        />
        <ToggleSwitch
          {...{ dbName: 'creditFacilityEnabled', disabled: true, register }}
        />
      </div>
      <div className={`${creditUtilizationStyle} p-4 rounded-b-lg`}>
        <ProgressBar
          {...{
            progressBarPercentage: convertToLowerDenomination(
              outstandingPayment / approvedCreditLimit
            )
          }}
        />
        <div className='flex justify-between w-full mt-4'>
          {creditUtilizationAmount.map(({ label, value }, index) => (
            <TextKeyValuePair
              key={index}
              {...{
                className: 'flex flex-col !gap-0',
                label,
                labelClass: 'font-semibold text-base text-white',
                value,
                valueClassName: 'font-medium text-sm text-white'
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HostCreditEntityCreditUtilization;
