import Image from 'next/image';
import { ClearRefinements } from 'react-instantsearch';

import { TextWithIcon } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const OrderListFilterHeader = ({
  onClickClearAll = () => {},
  setShowFilters,
  showFilters
}) => (
  <div className='self-top w-[18.5rem]'>
    <div className='flex mt-1 items-center justify-between'>
      <div
        className='flex gap-2 cursor-pointer px-3 py-1 bg-brand-gradient rounded-r-lg'
        onClick={() => setShowFilters(!showFilters)}
      >
        <TextWithIcon
          {...{
            className: 'self-center',
            icon: 'filter-icon.svg',
            iconHeight: 20,
            iconWidth: 20,
            label: 'Filters',
            labelStyle: 'text-base text-white font-light'
          }}
        />
        <div
          className='self-center'
          onClick={() => setShowFilters(!showFilters)}
        >
          <Image
            alt='view-icon'
            className='cursor-pointer'
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/${
              showFilters ? 'view-white-icon.svg' : 'hide-icon.svg'
            }`}
            style={{ height: 20, width: 20 }}
            width={0}
          />
        </div>
      </div>
      {showFilters && (
        <div onClick={onClickClearAll}>
          <ClearRefinements
            {...{
              classNames: {
                button: 'searchkit-clear-all-button',
                root: 'searchkit-clear-all-button-container items-center'
              },
              translations: {
                resetButtonText: 'Clear All'
              }
            }}
          />
        </div>
      )}
    </div>
    {showFilters && <div className='border-t border-platinum mt-4 mb-4'></div>}
  </div>
);

export default OrderListFilterHeader;
