import Image from 'next/image';
import { useRouter } from 'next/router';

import { Text, TextWithIcon, Tooltip } from '@/components/atomic/nuclei';
import { mediaStoreBaseURL, USER_EVENT_STAGE } from '@/config/common';
import { handleOnClickZendesk } from '@/helpers/zendeskTicket';
import { convertToShortMonthDateFormat, isDateBeforeToday } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getStatusBasedOptionsForUserEventCard } from '@/services/hostEvent.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const EventImage = ({
  checkoutEvent: { iconUrl },
  eventStatusSpecificClassName,
  imgStyle
}) => (
  <Image
    alt='icon'
    className={`rounded-lg ${imgStyle} ${eventStatusSpecificClassName ? 'grayscale' : ''}`}
    height={0}
    src={`${mediaStoreBaseURL}/${iconUrl}`}
    style={{ height: 64, width: 64 }}
    unoptimized={true}
    width={0}
  />
);

const UserEventInfoColumnOne = ({
  checkoutEvent: { event: { name } = {} } = {},
  eventAddress,
  eventStatusSpecificClassName,
  index,
  locationIcon,
  opsUser,
  userIcon
}) => (
  <div className='flex flex-col max-w-[30rem] truncate w-[30rem]'>
    <Text
      {...{
        className: `text-lg font-medium text-nero truncate max-w-[35rem] ${eventStatusSpecificClassName}`,
        content: name
      }}
    />
    <div className='flex items-center gap-4 mt-2'>
      <TextWithIcon
        {...{
          alt: 'planner',
          className: 'py-1',
          icon: userIcon,
          id: `event-planner-name-${index}`,
          label: opsUser?.name || 'NA',
          labelStyle: `text-sm text-nero ${eventStatusSpecificClassName}`
        }}
      />
      <TextWithIcon
        {...{
          alt: 'location',
          className: 'py-1',
          icon: locationIcon,
          id: `event-location-${index}`,
          label: eventAddress.formattedAddress || 'NA',
          labelStyle: `text-sm text-nero truncate max-w-80 ${eventStatusSpecificClassName}`
        }}
      />
    </div>
  </div>
);

const UserEventInfoColumnTwo = ({
  dateIcon,
  eventDate,
  eventIcon,
  eventStatusSpecificClassName,
  index,
  stage,
  userEventNumber,
  zendeskIcon,
  zendeskTicketId
}) => (
  <div className='flex flex-col ml-auto'>
    <div className='flex justify-content-end ml-auto gap-2 relative z-10 -top-[12px] -right-[12px]'>
      <Text
        {...{
          className: `${USER_EVENT_STAGE[stage].class} text-sm flex self-center font-medium px-2 py-1 rounded-lg relative`,
          content: USER_EVENT_STAGE[stage].label,
          id: `event-stage-${index}`
        }}
      />
      <TextWithIcon
        {...{
          alt: 'date',
          className: 'py-2 px-2 curve-shadow rounded-tr rounded-bl',
          icon: dateIcon,
          iconHeight: 20,
          iconWidth: 20,
          id: `event-date-${index}`,
          label: convertToShortMonthDateFormat(eventDate),
          labelStyle: `text-nero text-sm font-medium ${eventStatusSpecificClassName}`
        }}
      />
    </div>
    <div className='flex gap-4 justify-end'>
      <TextWithIcon
        {...{
          alt: 'zendesk',
          className: 'py-1',
          icon: zendeskIcon,
          id: `zendesk-ticket-number-${index}`,
          label: parseNumberedLabel(zendeskTicketId),
          labelStyle: `text-dim-gray text-sm font-medium ${eventStatusSpecificClassName}`,
          onClick: (e) => {
            e.stopPropagation();
            zendeskTicketId && handleOnClickZendesk(zendeskTicketId);
          }
        }}
      />
      <TextWithIcon
        {...{
          alt: 'event',
          className: 'py-1',
          icon: eventIcon,
          id: `event-number-${index}`,
          label: parseNumberedLabel(userEventNumber),
          labelStyle: `text-dim-gray text-sm font-medium ${eventStatusSpecificClassName}`
        }}
      />
    </div>
  </div>
);

const UserEventCard = ({ index, userEvent }) => {
  const {
    id,
    selectedUserEventDetail: {
      checkoutEvent,
      eventAddress,
      eventDate,
      opsUser
    },
    stage,
    userEventNumber,
    zendeskTicketId
  } = userEvent;
  const router = useRouter();

  const isPastEvent = isDateBeforeToday({ date: eventDate });
  const {
    dateIcon,
    eventIcon,
    eventStatusSpecificClassName = '',
    imgStyle,
    locationIcon,
    userIcon,
    zendeskIcon
  } = getStatusBasedOptionsForUserEventCard({ isPastEvent });

  const tooltipConfigList = [
    {
      target: `event-planner-name-${index}`,
      text: 'Event Planner Name'
    },
    {
      target: `event-location-${index}`,
      text: 'Event Location'
    },
    {
      target: `event-stage-${index}`,
      text: 'Event Stage'
    },
    {
      target: `event-date-${index}`,
      text: 'Event Date'
    },
    {
      target: `zendesk-ticket-number-${index}`,
      text: 'Zendesk Ticket Number'
    },
    {
      target: `event-number-${index}`,
      text: 'Event Number'
    }
  ];

  return (
    <div
      className='flex gap-3 bg-white p-3 rounded-lg border border-white relative hover:border-brand'
      onClick={() =>
        router.push(
          getPageURL({
            pageName: PAGE_NAME.EVENT_CART_LIST.label,
            pathParams: { userEventId: id }
          })
        )
      }
    >
      <EventImage
        {...{ checkoutEvent, eventStatusSpecificClassName, imgStyle }}
      />
      <UserEventInfoColumnOne
        {...{
          checkoutEvent,
          eventAddress,
          eventStatusSpecificClassName,
          index,
          locationIcon,
          opsUser,
          userIcon
        }}
      />
      <UserEventInfoColumnTwo
        {...{
          dateIcon,
          eventDate,
          eventIcon,
          eventStatusSpecificClassName,
          index,
          stage,
          userEventNumber,
          zendeskIcon,
          zendeskTicketId
        }}
      />
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

export default UserEventCard;
