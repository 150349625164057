const ToggleSwitch = ({
  dbName,
  disabled,
  label,
  labelPosition = 'Left',
  labelStyle = 'self-center text-base text-dim-gray font-medium',
  register,
  style = '',
  toggleStyle = ''
}) => (
  <div className={`flex gap-2 ${style}`}>
    {labelPosition === 'Left' && <span className={labelStyle}>{label}</span>}
    <label className='switch'>
      <input
        disabled={disabled}
        onChange={(e) => e.target.checked}
        type='checkbox'
        {...register(dbName)}
      />
      <span className={`toggle-slider round ${toggleStyle}`}></span>
    </label>
    {labelPosition === 'Right' && <span className={labelStyle}>{label}</span>}
  </div>
);

export default ToggleSwitch;
