import Image from 'next/image';

import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { convertToMonthYearFormat } from '@/lib/time';

const LetsPlanFirstEvent = ({
  createdAt,
  description = `Let's Plan The First Event!`,
  labelClassName,
  title = 'Host Since'
}) => (
  <div className='flex flex-col w-full gap-1 justify-center items-center'>
    <TextKeyValuePair
      {...{
        className: 'flex flex-row justify-center items-center !gap-1',
        label: title,
        labelClass: `text-sm font-medium ${labelClassName}`,
        value: convertToMonthYearFormat({ dateTimeString: createdAt }),
        valueClassName: `font-semibold text-base ${labelClassName}`
      }}
    />
    <Text
      {...{
        className: `mb-3 text-xl font-semibold ${labelClassName}`,
        content: description
      }}
    />
    <Image
      alt='lets-plan-first-event'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/lets-plan-first-event.svg`}
      style={{ width: 293, height: 203 }}
      width={0}
    />
  </div>
);

export default LetsPlanFirstEvent;
