import { getAvatarText } from '@/lib/utils';

const Avatar = ({
  badgeStyle = 'bg-brand-gradient w-[100px] h-[100px]',
  textStyle = 'text-white',
  name
}) => {
  const avatarText = getAvatarText({ name });

  return (
    <div
      className={`${badgeStyle} text-[48px] rounded-lg flex items-center justify-center font-semibold`}
    >
      <span className={textStyle}>{avatarText}</span>
    </div>
  );
};

export default Avatar;
