import Image from 'next/image';
import { Fragment } from 'react';

import { ProgressBar, Text, Tooltip } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  copyToClipboardEPICGeneation,
  getEPICExplanationStringInput,
  getEPICGenrationCompletionPercentage
} from '@/services/userEventCartList.service';

const EPICExplanation = ({ epicParameters }) => (
  <div className='text-sm leading-6'>
    {getEPICExplanationStringInput({
      epicParameters
    }).map(({ prefix, value }) => (
      <Fragment key={value}>
        {prefix || ''}
        <b>{value}</b>
      </Fragment>
    ))}
  </div>
);

const EPICBriefHeader = ({
  doEPICGeneratedFieldsMeetValidation,
  epicParameters,
  setShowToast
}) => {
  const tooltipConfigListForEPICGenerated = [
    { target: 'epic-completed', text: 'EPIC Completed' },
    { target: 'copy-epic', text: 'Copy EPIC' }
  ];
  const tooltipConfigListForEPICInProgress = [
    { target: 'epic-in-progress', text: 'EPIC In-progress' }
  ];

  const inProgressEpicParameters = {
    icon: 'epic-in-progress-icon',
    imgAlt: 'in progress',
    imgId: 'epic-in-progress',
    tooltipConfigList: tooltipConfigListForEPICInProgress
  };

  const completedEpicParameters = {
    icon: 'checked-red-white-bg-icon',
    imgAlt: 'completed',
    imgId: 'epic-completed',
    tooltipConfigList: tooltipConfigListForEPICGenerated
  };

  const { icon, imgAlt, imgId, tooltipConfigList } =
    doEPICGeneratedFieldsMeetValidation
      ? completedEpicParameters
      : inProgressEpicParameters;

  return (
    <div className='flex justify-between items-center'>
      <div className='flex items-center gap-2'>
        <Image
          alt={imgAlt}
          height={0}
          id={imgId}
          src={`${staticMediaStoreBaseURL}/icons/${icon}.svg`}
          style={{ height: 16, width: 16 }}
          width={0}
        />
        <div className='epic-font-style'>
          {['Event', 'Profile', 'Indicative', 'Code'].map((value) => (
            <Text
              key={value}
              {...{
                className: 'text-base pr-1.5 bold-first-character',
                content: value,
                HtmlTag: 'span'
              }}
            />
          ))}
          <Text
            {...{
              className: 'text-base font-bold',
              content: '(EPIC)',
              HtmlTag: 'span'
            }}
          />
        </div>
      </div>
      {doEPICGeneratedFieldsMeetValidation && (
        <Image
          alt='copy'
          className='cursor-pointer'
          height={0}
          id='copy-epic'
          onClick={() =>
            copyToClipboardEPICGeneation({
              epicParameters,
              setShowToast
            })
          }
          src={`${staticMediaStoreBaseURL}/icons/copy-icon-white.svg`}
          style={{ height: 20, width: 20 }}
          width={0}
        />
      )}
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

const EPICBrief = ({
  containerStyle = 'bg-brand-gradient',
  setShowToast,
  userEvent
}) => {
  const {
    userEventDetails: [
      {
        checkoutEvent = {},
        eventAddress = {},
        eventContactName,
        eventDate = '',
        eventVerticals,
        expectedGuestCount
      } = {}
    ] = [],
    user
  } = userEvent;
  const { hostSegment } = user || {};
  const requesterType = hostSegment?.name || null;

  const progressBarPercentage = getEPICGenrationCompletionPercentage(userEvent);

  const doEPICGeneratedFieldsMeetValidation =
    getEPICGenrationCompletionPercentage(userEvent) === '100.00';
  const showProgressBar = !doEPICGeneratedFieldsMeetValidation;
  const epicParameters = {
    city: eventAddress?.formattedAddress,
    eventDate,
    eventType: checkoutEvent?.event?.name,
    guests: expectedGuestCount,
    hostName: eventContactName,
    hostType: requesterType,
    verticals: eventVerticals
  };

  return (
    <div
      className={`flex flex-col gap-3 rounded-lg text-white p-4 ${containerStyle}`}
    >
      <EPICBriefHeader
        {...{
          doEPICGeneratedFieldsMeetValidation,
          epicParameters,
          setShowToast
        }}
      />
      {showProgressBar && <ProgressBar {...{ progressBarPercentage }} />}
      <EPICExplanation
        {...{
          epicParameters
        }}
      />
    </div>
  );
};

export default EPICBrief;
