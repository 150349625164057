import { useEffect, useState } from 'react';
import { useCurrentRefinements, useNumericMenu } from 'react-instantsearch';

import {
  DateRangeFilterWithDatePicker,
  Text
} from '@/components/atomic/nuclei';

const DEFAULT_REFINEMENT = encodeURI('{}');

const handleRadioChange = ({
  customRangeKey,
  event,
  value,
  refine,
  setEndDate,
  setSelectedDateKey,
  setShowCustomRange,
  setStartDate
}) => {
  const { value: targetValue } = event.target;
  setStartDate();
  setEndDate();
  setSelectedDateKey(targetValue);
  setShowCustomRange(targetValue === customRangeKey);
  if (targetValue !== customRangeKey) {
    return refine(value);
  }
  return refine(DEFAULT_REFINEMENT);
};

const RadioInputWithLabel = ({
  checked,
  labelName,
  name,
  onChange,
  showRadio,
  value
}) =>
  showRadio && (
    <li className='relative block searchkit-filter-menu-item cursor-pointer'>
      <label className='cursor-pointer flex items-center self-center'>
        <input
          {...{
            checked,
            className:
              'checked:content-searchkit-filter-menu-item-checked searchkit-filter-menu-item-checked searchkit-radio',
            name,
            onChange,
            type: 'radio',
            value
          }}
        />
        <span className='before:bg-searchkit-filter-menu-item empty-radio-span inline-block w-5 h-5'></span>
        <Text
          {...{
            className: 'text-sm text-dim-gray font-medium pl-2',
            content: labelName
          }}
        />
      </label>
    </li>
  );

const NumericFilterWithDatePicker = (props) => {
  const { items: numericMenuItems, refine } = useNumericMenu(props);
  const { items: currentRefinementsItems } = useCurrentRefinements();
  const {
    attribute,
    clearDateFilter,
    customRangeKey,
    showAdditionalFilters = true
  } = props;

  const [selectedDateKey, setSelectedDateKey] = useState(DEFAULT_REFINEMENT);
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const isDateFilterApplied = currentRefinementsItems.find(
    ({ attribute: selectedAttribute }) => selectedAttribute === attribute
  );

  const clearFilter = () => {
    setStartDate();
    setEndDate();
    setShowCustomRange(false);
    setSelectedDateKey(DEFAULT_REFINEMENT);
  };

  useEffect(() => {
    clearFilter();
  }, [clearDateFilter]);

  useEffect(() => {
    if (!isDateFilterApplied) {
      clearFilter();
    }
  }, []);

  return (
    <>
      <ul className='flex flex-col gap-4'>
        {showAdditionalFilters &&
          numericMenuItems.map(({ label, value }) => (
            <RadioInputWithLabel
              key={value}
              {...{
                checked: selectedDateKey === value,
                labelName: label,
                name: attribute,
                onChange: (event) => {
                  handleRadioChange({
                    event,
                    refine,
                    setEndDate,
                    setSelectedDateKey,
                    setShowCustomRange,
                    setStartDate,
                    value
                  });
                },
                showRadio: true,
                value
              }}
            />
          ))}
        <RadioInputWithLabel
          key={customRangeKey}
          {...{
            checked: selectedDateKey === customRangeKey,
            labelName: 'Custom Range',
            name: customRangeKey,
            onChange: (event) => {
              handleRadioChange({
                customRangeKey,
                event,
                refine,
                setEndDate,
                setSelectedDateKey,
                setShowCustomRange,
                setStartDate,
                value: null
              });
            },
            showRadio: true,
            value: customRangeKey
          }}
        />
      </ul>
      {showCustomRange && (
        <DateRangeFilterWithDatePicker
          {...{
            attribute,
            endDate,
            setEndDate,
            setStartDate,
            showCustomRange,
            startDate
          }}
        />
      )}
    </>
  );
};

export default NumericFilterWithDatePicker;
