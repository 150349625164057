import Image from 'next/image';
import ReactDatePicker from 'react-datepicker';

import { staticMediaStoreBaseURL } from '@/config/common';
import { getFormattedDate } from '@/lib/time';

const DatePickerWithLabel = ({
  datePickerClassName,
  dbName,
  disabled = false,
  errors,
  label,
  placeholder,
  selectedEventDate,
  setSelectedEventDate,
  setValue
}) => {
  const invalidMessage = errors[dbName]?.message;
  return (
    <div className='w-full flex flex-col gap-2 h-20'>
      {label && (
        <label className='text-nero text-base font-medium'>{label}</label>
      )}
      <div
        className={`flex flex-row items-center border border-platinum rounded-lg px-2 h-12 ${invalidMessage ? '!border-brand' : ''} ${!disabled ? 'hover:border-brand' : ''}`}
      >
        <Image
          alt='date-icon-black'
          className='cursor-pointer'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/${disabled ? 'date-black-icon.svg' : 'date-brand-icon.svg'}`}
          style={{ height: 24, width: 24 }}
          width={0}
        />
        <ReactDatePicker
          className={datePickerClassName}
          dateFormat='dd/MM/yyyy'
          disabled={disabled}
          name={dbName}
          onChange={(e) => {
            if (e) {
              const formatDate = getFormattedDate({
                date: e,
                format: 'YYYY-MM-DD'
              });
              setValue(dbName, formatDate);
              setSelectedEventDate(e);
              return;
            }
            setValue(dbName, null);
            setSelectedEventDate(null);
          }}
          placeholderText={placeholder}
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [5, 10]
              }
            },
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
                rootBoundary: 'viewport',
                tether: false
              }
            }
          ]}
          popperPlacement='top-end'
          scrollableYearDropdown={true}
          selected={selectedEventDate}
          showMonthDropdown={true}
          showYearDropdown={true}
        />
      </div>
      {invalidMessage && (
        <span
          className={
            'mt-0 w-full text-red-500 text-xs font-medium capitalize invalid-feedback'
          }
        >
          {invalidMessage}
        </span>
      )}
    </div>
  );
};

export default DatePickerWithLabel;
