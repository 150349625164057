import _ from 'lodash';

const FRACTIONAL_PRECISION = 100;

const resequenceParts = (partsToResequence) => {
  const { minusSign, currency, literal } = _.keyBy(partsToResequence, 'type');
  if (minusSign && currency) {
    return [
      currency,
      literal,
      minusSign,
      ...partsToResequence.filter(
        (part) => !['currency', 'minusSign', 'literal'].includes(part.type)
      )
    ];
  }
  return partsToResequence;
};

export const convertToCurrencyFormat = ({
  roundOff = false,
  value,
  withCurrencyPrefix = true
}) => {
  const options = {
    currency: withCurrencyPrefix ? 'AED' : undefined,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: withCurrencyPrefix ? 'currency' : 'decimal'
  };

  const parsedValue = roundOff ? Math.round(value) : value;
  const formatter = new Intl.NumberFormat('en-US', options);
  let parts = formatter.formatToParts(parsedValue);

  if (withCurrencyPrefix) {
    parts = resequenceParts(parts);
  }

  return parts.map((part) => part.value).join('');
};

const convertToUpperDenomination = ({
  formatDecimalPrecision = 2,
  returnType = 'float',
  value = 0
}) => {
  const parsedValue = Number(value);

  if (Number.isNaN(parsedValue)) {
    throw new Error(
      `unable to parse the value ${value}, parsing it results in NaN`
    );
  }

  const upperDenominationValue = parseFloat(parsedValue) / FRACTIONAL_PRECISION;

  switch (returnType.toLowerCase()) {
    case 'float':
      return parseFloat(upperDenominationValue.toFixed(formatDecimalPrecision));
    case 'string':
      return parseFloat(upperDenominationValue).toFixed(formatDecimalPrecision);
    default:
      throw new Error('unknown returnType');
  }
};

export const convertToLowerDenomination = (value) =>
  Math.round(value * FRACTIONAL_PRECISION);

export const getFloatStringToTwoDecimalPlaces = (value) =>
  parseFloat(value).toFixed(2);

const getFloatStringWithZeroFractionTrimmed = (floatString) => {
  const [number, fraction] = floatString.split('.');
  return Math.round(fraction) === 0 ? number : floatString;
};

export const parsePriceValueForAPI = (price) =>
  Math.round(parseFloat(convertToLowerDenomination(price)).toFixed(2));

export const parseFormatPriceValueFromAPI = (value = 0) =>
  convertToUpperDenomination({
    value,
    returnType: 'string'
  });

export const parseFormatPriceNumberValueFromAPI = (value) =>
  convertToUpperDenomination({
    value,
    returnType: 'float'
  });

export const decorateNumberForDisplay = (value) =>
  getFloatStringToTwoDecimalPlaces(value);

export const calculateMarginPercent = (value, total) =>
  ((total - value) * 100) / total;

export const parseFormatPercentValueFromAPI = (value) =>
  convertToUpperDenomination({
    value,
    returnType: 'string'
  });

export const formatDiscountObjectToString = ({ value, type }) => {
  let prefix = '';
  let suffix = '';
  if (type === 'Percentage') {
    prefix = '';
    suffix = ' % off';
  }
  if (type === 'Absolute') {
    prefix = 'Flat ';
    suffix = ' off';
  }

  return `${prefix}${convertToUpperDenomination({
    value,
    returnType: 'string'
  })}${suffix}`;
};

// TODO: Sidd - after host-cart-v1 try to deprecate this function
export const formatToTwoDecimalString = ({
  value,
  convertValueToUpperDenomination = true,
  trimZeroFraction = true
}) => {
  const parsedValue = Number(value);

  if (Number.isNaN(parsedValue)) {
    throw new Error(
      `unable to parse the value ${value}, parsing it results in NaN`
    );
  }

  const valueToProcess = convertValueToUpperDenomination
    ? convertToUpperDenomination({ value: parsedValue })
    : value;

  const floatStringToTwoDecimalPlaces =
    getFloatStringToTwoDecimalPlaces(valueToProcess);

  return trimZeroFraction
    ? getFloatStringWithZeroFractionTrimmed(floatStringToTwoDecimalPlaces)
    : floatStringToTwoDecimalPlaces;
};

// TODO: TJ think of more concise names for parseFormatPriceNumberValueFromAPI, parseFormatPercentValueFromAPI, parseFormatPriceValueFromAPI
