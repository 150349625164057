import _ from 'lodash';

export const NAVIGATION_INDEX =
  process.env.NEXT_PUBLIC_PLANNER_NAVIGATION_INDEX;

export const TAB_CATEGORY_ATTRIBUTE = 'entityType';

export const DEFAULT_HITS_PER_PAGE = 6;

export const HOST_CREDIT_ENTITY_DROPDOWN_HIT_LIMIT = 5;

export const TAB_CATEGORY_ENUM = {
  ALL: 'ALL',
  CART: 'CART',
  HOST_CREDIT_ENTITY: 'HOST_CREDIT_ENTITY',
  HOST: 'HOST',
  ORDER: 'ORDER',
  USER_EVENT: 'USER_EVENT'
};

export const DEFAULT_TAB_CATEGORY = TAB_CATEGORY_ENUM.HOST;

export const ALL_TAB_CATEGORY_CONFIG = {
  activeIcon: 'menu-dashboard-red-icon',
  className: 'all-badge',
  icon: 'menu-dashboard-icon',
  key: TAB_CATEGORY_ENUM.ALL,
  label: 'All'
};

export const TAB_CATEGORY_CONFIG_LIST = [
  {
    activeIcon: 'cart-white-icon',
    className: 'in-progress-badge',
    icon: 'cart-brand-icon',
    key: TAB_CATEGORY_ENUM.CART,
    label: 'Cart',
    sortOrder: 2
  },
  {
    activeIcon: 'host-white-icon',
    className: 'text-bluish-cyan',
    icon: 'host-brand-icon',
    key: TAB_CATEGORY_ENUM.HOST,
    label: 'Host',
    sortOrder: 0
  },
  {
    activeIcon: 'order-white-icon',
    className: 'submitted-badge',
    icon: 'order-brand-icon',
    key: TAB_CATEGORY_ENUM.ORDER,
    label: 'Order',
    sortOrder: 3
  },
  {
    activeIcon: 'user-event-white-icon',
    className: 'past-badge',
    icon: 'user-event-brand-icon',
    key: TAB_CATEGORY_ENUM.USER_EVENT,
    label: 'User Event',
    sortOrder: 1
  },
  {
    activeIcon: 'tab-host-credit-entity',
    className: 'submitted-badge',
    icon: 'host-credit-entity-red-icon',
    key: TAB_CATEGORY_ENUM.HOST_CREDIT_ENTITY,
    label: 'Credit Entity',
    sortOrder: 4
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

const SEARCHABLE_ATTRIBUTE_HOST_MOBILE = {
  esDocKey: 'hostMobile',
  key: 'HOST_MOBILE',
  label: 'Host Mobile'
};

const SEARCHABLE_ATTRIBUTE_USER_EVENT_NUMBER = {
  esDocKey: 'userEventNumber',
  key: 'USER_EVENT_NUMBER',
  label: 'User Event Number'
};

export const SEARCHABLE_ATTRIBUTE_FOR_ALL_TAB = [
  {
    esDocKey: 'everywhere',
    key: 'EVERYWHERE',
    label: 'Everywhere',
    sortOrder: 0
  },
  {
    ...SEARCHABLE_ATTRIBUTE_HOST_MOBILE,
    sortOrder: 1
  },
  {
    ...SEARCHABLE_ATTRIBUTE_USER_EVENT_NUMBER,
    sortOrder: 2
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

const SEARCHABLE_ATTRIBUTES_FOR_HOST_TAB = [
  {
    esDocKey: 'hostName',
    key: 'HOST_NAME',
    label: 'Name',
    sortOrder: 0
  },
  {
    ...SEARCHABLE_ATTRIBUTE_HOST_MOBILE,
    sortOrder: 1
  },
  {
    esDocKey: 'hostNumber',
    key: 'HOST_NUMBER',
    label: 'Host Number',
    sortOrder: 2
  },
  {
    esDocKey: 'hostCreditEntityName',
    key: 'HOST_CREDIT_ENTITY_NAME',
    label: 'Credit Entity Name',
    sortOrder: 3
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

const SEARCHABLE_ATTRIBUTE_FOR_ORDER_TAB = [
  {
    esDocKey: 'orderNumber',
    key: 'ORDER_NUMBER',
    label: 'Order Number',
    sortOrder: 0
  },
  {
    ...SEARCHABLE_ATTRIBUTE_USER_EVENT_NUMBER,
    sortOrder: 1
  },
  {
    ...SEARCHABLE_ATTRIBUTE_HOST_MOBILE,
    sortOrder: 2
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

const SEARCHABLE_ATTRIBUTE_FOR_CART_TAB = [
  {
    esDocKey: 'cartNumber',
    key: 'CART_NUMBER',
    label: 'Cart Number',
    sortOrder: 0
  },
  {
    ...SEARCHABLE_ATTRIBUTE_USER_EVENT_NUMBER,
    sortOrder: 1
  },
  {
    esDocKey: 'cartName',
    key: 'CART_NAME',
    label: 'Cart Name',
    sortOrder: 2
  },
  {
    ...SEARCHABLE_ATTRIBUTE_HOST_MOBILE,
    sortOrder: 3
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

const SEARCHABLE_ATTRIBUTE_FOR_USER_EVENT_TAB = [
  {
    esDocKey: 'userEventNumber',
    key: 'USER_EVENT_NUMBER',
    label: 'User Event Number',
    sortOrder: 0
  },
  {
    esDocKey: 'zendeskTicketNumber',
    key: 'ZENDESK_TICKET_NUMBER',
    label: 'Zendesk Ticket Number',
    sortOrder: 1
  },

  {
    ...SEARCHABLE_ATTRIBUTE_HOST_MOBILE,
    sortOrder: 2
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

const SEARCHABLE_ATTRIBUTE_FOR_HOST_CREDIT_ENTITY_TAB = [
  {
    esDocKey: 'accountManagerName',
    key: 'ACCOUNT_MANAGER_NAME',
    label: 'Account Manager Name',
    sortOrder: 1
  },
  {
    esDocKey: 'hostCreditEntityName',
    key: 'HOST_CREDIT_ENTITY_NAME',
    label: 'Credit Entity Name',
    sortOrder: 0
  },
  {
    esDocKey: 'hostCreditEntityNumber',
    key: 'HOST_CREDIT_ENTITY_NUMBER',
    label: 'Credit Entity Number',
    sortOrder: 2
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

export const TAB_SEARCHABLE_ATTRIBUTE_MAP = {
  [TAB_CATEGORY_ENUM.ALL]: _.keyBy(
    SEARCHABLE_ATTRIBUTE_FOR_ALL_TAB,
    'esDocKey'
  ),
  [TAB_CATEGORY_ENUM.HOST]: _.keyBy(
    SEARCHABLE_ATTRIBUTES_FOR_HOST_TAB,
    'esDocKey'
  ),
  [TAB_CATEGORY_ENUM.CART]: _.keyBy(
    SEARCHABLE_ATTRIBUTE_FOR_CART_TAB,
    'esDocKey'
  ),
  [TAB_CATEGORY_ENUM.ORDER]: _.keyBy(
    SEARCHABLE_ATTRIBUTE_FOR_ORDER_TAB,
    'esDocKey'
  ),
  [TAB_CATEGORY_ENUM.USER_EVENT]: _.keyBy(
    SEARCHABLE_ATTRIBUTE_FOR_USER_EVENT_TAB,
    'esDocKey'
  ),
  [TAB_CATEGORY_ENUM.HOST_CREDIT_ENTITY]: _.keyBy(
    SEARCHABLE_ATTRIBUTE_FOR_HOST_CREDIT_ENTITY_TAB,
    'esDocKey'
  )
};
