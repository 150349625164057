import { PropTypes } from 'prop-types';

const Divider = ({
  border = 'border-t',
  color = 'border-gray-300',
  space = 'my-4'
}) => <div className={`${border} ${space} ${color}`}></div>;

export default Divider;

Divider.prototype = {
  border: PropTypes.string,
  color: PropTypes.string,
  space: PropTypes.string
};
