import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { PRODUCT_TYPE } from '@/config/common';
import {
  convertToCurrencyFormat,
  formatDiscountObjectToString,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';

const parseToCurrencyFormat = (value) =>
  convertToCurrencyFormat({ value: parseFormatPriceValueFromAPI(value || 0) });

const QuoteProductPriceLabel = ({ price }) => (
  <Text
    {...{
      className: 'text-sm md:text-lg lg-l:text-xl mt-4',
      content: `Price Starting from ${parseToCurrencyFormat(price?.minPrice)}`
    }}
  />
);

const CartProductPriceLabel = ({ availableDiscount, price }) => (
  <div>
    <div className='flex font-400 items-center'>
      <Text
        {...{
          className: 'text-sm md:text-lg font-semibold',
          content: parseToCurrencyFormat(price?.priceToApply || 0)
        }}
      />
      {Boolean(availableDiscount) && (
        <TextKeyValuePair
          {...{
            className: 'flex flex-row', // TODO: Viresh to fix UI.
            label: parseToCurrencyFormat(price.price),
            labelClass:
              'line-through ms-3 text-dim-gray text-xs md:text-lg font-medium',
            value: formatDiscountObjectToString({
              type: 'Percentage',
              value: availableDiscount.value
            }),
            valueClassName: 'ms-3 text-green text-xs md:text-lg font-medium'
          }}
        />
      )}
    </div>
    <Text
      {...{
        className: 'text-xs text-brand mt-2 font-medium md:hidden',
        content: 'Excluding VAT'
      }}
    />
  </div>
);

const productPriceLabelMap = {
  [PRODUCT_TYPE.CART]: CartProductPriceLabel,
  [PRODUCT_TYPE.QUOTE]: QuoteProductPriceLabel
};

const ProductPriceLabel = ({ availableDiscount, price, productType }) => {
  const Label = productPriceLabelMap[productType];
  return <Label {...{ availableDiscount, price }} />;
};

export default ProductPriceLabel;
