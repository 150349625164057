import Image from 'next/image';
import { useState } from 'react';

import { InputField, SelectTags, Text } from '@/components/atomic/nuclei';
import {
  TAG_OPTIONS_SELECTION_MODE,
  staticMediaStoreBaseURL
} from '@/config/common';
import {
  hostProfileTabs,
  updateSocialMediaProfileBasedOnValue
} from '@/services/hostProfile.service';

const SocialProfileIconList = ({
  isEditable,
  setSocialProfileList,
  setValue,
  socialProfileList,
  watch
}) => (
  <div className='flex gap-3 cursor-pointer my-2'>
    {socialProfileList
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(
        (
          {
            alt,
            dbName,
            icon: {
              activeIcon,
              disabledIcon,
              disabledSelectedIcon,
              selectedIcon
            },
            isSelected
          },
          index
        ) => {
          const activeIconURL = isSelected ? selectedIcon : activeIcon;
          const disabledIconURL = isSelected
            ? disabledSelectedIcon
            : disabledIcon;
          const iconURL = isEditable ? activeIconURL : disabledIconURL;
          return (
            <Image
              alt={alt}
              height={0}
              key={iconURL}
              onClick={() => {
                isEditable &&
                  setSocialProfileList((prevState) => {
                    const updatedList = [...prevState];
                    updatedList[index] = {
                      ...updatedList[index],
                      isSelected: !updatedList[index].isSelected
                    };
                    return updatedList;
                  });
                if (isSelected) {
                  setValue(
                    `socialProfiles.${dbName}`,
                    watch(`socialProfiles.${dbName}`)
                  );
                }
              }}
              src={`${staticMediaStoreBaseURL}/icons/${iconURL}`}
              style={{
                width: 48,
                height: 48,
                pointerEvents: isEditable ? 'auto' : 'none'
              }}
              width={0}
            />
          );
        }
      )}
  </div>
);

const SocialProfileInputFieldList = ({
  isEditable,
  register,
  socialProfileList
}) =>
  socialProfileList.map(
    ({ dbName, inputIcon: { activeIcon, disabledIcon }, isSelected, name }) => {
      const inputIconURL = isEditable ? activeIcon : disabledIcon;
      return (
        isSelected && (
          <InputField
            key={dbName}
            {...{
              className: 'rounded-lg pl-10 !py-4 my-1 ',
              dbName,
              disabled: !isEditable,
              iconClass: '!top-4',
              iconURL: inputIconURL,
              inputGroup: 'flex flex-col gap-2 flex-1',
              labelClass: 'text-nero text-base font-medium',
              maxLength: 20,
              placeholder: `Enter ${name} profile link`,
              register: { ...register(`socialProfiles.${dbName}`) }
            }}
          />
        )
      );
    }
  );

const HostProfileStepTwo = ({
  formActiveStepId,
  hostProfileMetadata,
  isEditable,
  register,
  setValue,
  watch
}) => {
  const { ethnicityList, preferredLanguageList } = hostProfileMetadata;
  const [socialProfileList, setSocialProfileList] = useState(
    updateSocialMediaProfileBasedOnValue({
      socialProfiles: watch('socialProfiles')
    })
  );
  const formStepVisibilityClass =
    formActiveStepId === hostProfileTabs.ADDITIONAL_PROFILE.id
      ? 'block'
      : 'hidden';

  return (
    <div
      id={hostProfileTabs.ADDITIONAL_PROFILE.id}
      className={formStepVisibilityClass}
    >
      <div className='bg-white rounded-lg px-6 flex flex-col gap-4 mb-4'>
        <SelectTags
          {...{
            formActiveStepId,
            isEditable,
            selectedTagOptions: watch('preferredLanguageId'),
            setSelectedTagOptions: (value) =>
              setValue('preferredLanguageId', value),
            tagOptions: preferredLanguageList,
            tagOptionsSelectionMode: TAG_OPTIONS_SELECTION_MODE.SINGLE,
            tagsFieldLabel: { label: 'Preferred Language' }
          }}
        />
        <SelectTags
          {...{
            formActiveStepId,
            isEditable,
            selectedTagOptions: watch('ethnicityId'),
            setSelectedTagOptions: (value) => setValue('ethnicityId', value),
            tagOptions: ethnicityList,
            tagOptionsSelectionMode: TAG_OPTIONS_SELECTION_MODE.SINGLE,
            tagsFieldLabel: { label: 'Ethnicity' }
          }}
        />
        <div>
          <Text
            {...{
              className: 'text-base font-medium',
              content: 'Social Profile'
            }}
          />
          <SocialProfileIconList
            {...{
              isEditable,
              setSocialProfileList,
              setValue,
              socialProfileList,
              watch
            }}
          />
          <SocialProfileInputFieldList
            {...{ isEditable, register, socialProfileList }}
          />
        </div>
      </div>
    </div>
  );
};

export default HostProfileStepTwo;
