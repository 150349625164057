import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import {
  Configure,
  InstantSearch,
  useInfiniteHits,
  useSearchBox
} from 'react-instantsearch';

import { staticMediaStoreBaseURL } from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const Hit = ({
  dbName,
  hit,
  setIsFocused,
  setSearchQuery,
  setSelectedHostCreditEntityName,
  setValue
}) => {
  const { entityName, entityId } = hit;
  const { refine } = useSearchBox();
  return (
    <div
      className='cursor-pointer w-full'
      onClick={() => {
        setSelectedHostCreditEntityName('');
        setTimeout(() => {
          refine(entityName);
          setSearchQuery(entityName);
          setSelectedHostCreditEntityName(entityName);
          setValue(dbName, entityId);
          setIsFocused(false);
        }, 0);
      }}
    >
      {entityName}
    </div>
  );
};

const InfiniteHits = ({ hitComponent: HitComponent, ...props }) => {
  const { hits, isLastPage, showMore } = useInfiniteHits(props);
  const sentinelRef = useRef(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [isLastPage, showMore]);

  return (
    <div className='ais-InfiniteHits'>
      <ul className='ais-InfiniteHits-list'>
        {hits.map((hit) => (
          <li
            className='ais-InfiniteHits-item hover:bg-[#FDF1EE] hover:text-nero'
            key={hit.objectID}
          >
            <HitComponent hit={hit} />
          </li>
        ))}
        <li
          aria-hidden='true'
          className='ais-InfiniteHits-sentinel'
          ref={sentinelRef}
        />
      </ul>
    </div>
  );
};

const CustomSearchBox = ({
  clearErrors,
  dbName,
  disableSearchBox,
  errors,
  isFocused,
  placeholder,
  selectFormIcon,
  selectedHostCreditEntityName,
  setIsFocused,
  setSearchQuery,
  setSelectedHostCreditEntityName,
  setValue
}) => {
  const { query, refine } = useSearchBox();
  const handleSearchChange = (value) => {
    clearErrors(dbName);
    refine(value);
    setIsFocused(true);
    setSearchQuery(value);
    setSelectedHostCreditEntityName('');
    setValue(dbName, '');
  };

  const refineAndSetValueOnPageLoad = () => {
    clearErrors(dbName);
    refine(selectedHostCreditEntityName);
    setIsFocused(true);
    setSearchQuery(selectedHostCreditEntityName);
  };

  useEffect(() => {
    refineAndSetValueOnPageLoad();
    setIsFocused(false);
  }, [selectedHostCreditEntityName]);

  const errorMessage = errors?.[dbName]?.message;
  return (
    <div className='relative'>
      <span className='absolute top-3 left-2'>
        <Image
          {...{
            alt: 'search',
            height: 0,
            src: `${staticMediaStoreBaseURL}/icons/${selectFormIcon}`,
            style: { width: 24, height: 24 },
            width: 0
          }}
        />
      </span>
      <input
        {...{
          className: `flex items-center h-12 text-base font-medium text-nero w-full border text-gray-900 px-2 outline-none placeholder:text-grey-400 rounded-lg pl-10 !py-4 ${errorMessage ? 'border-brand' : ''}`,
          disabled: disableSearchBox,
          onChange: (e) => handleSearchChange(e.target.value),
          onFocus: () => setIsFocused(true),
          placeholder,
          value: query
        }}
      />
      <span className='absolute top-4 left-[97%]'>
        <Image
          {...{
            alt: 'search',
            className: `transform ${isFocused ? 'rotate-180' : 'rotate-0'}`,
            height: 0,
            src: `${staticMediaStoreBaseURL}/icons/angle-down.svg`,
            style: { width: 16, height: 16 },
            width: 0
          }}
        />
      </span>
      {errorMessage && (
        <div className='text-xs text-red-500 pt-2'>{errorMessage}</div>
      )}
    </div>
  );
};

const SearchkitSelectFormInput = ({
  addNewLabel,
  clearErrors,
  configureProps,
  dbName,
  disableSearchBox,
  errors,
  indexName,
  label: { name, className },
  onClickAddNewEntity,
  placeholder,
  searchClient,
  selectedHostCreditEntityName,
  selectFormIcon,
  setSearchQuery,
  setSelectedHostCreditEntityName,
  setValue,
  showAddNewLabel = true,
  showLabel = true
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const selectFormInputRef = useRef(null);

  useOnClickOutside(selectFormInputRef, () => setIsFocused(false));

  return (
    <div
      className='mb-10'
      ref={selectFormInputRef}
    >
      {showLabel && <label className={className}>{name}</label>}
      <InstantSearch
        {...{
          future: {
            preserveSharedStateOnUnmount: true
          },
          indexName,
          searchClient
        }}
      >
        <Configure {...configureProps} />
        <div>
          <CustomSearchBox
            aria-label='Search'
            {...{
              clearErrors,
              dbName,
              disableSearchBox,
              errors,
              isFocused,
              placeholder,
              selectFormIcon,
              selectedHostCreditEntityName,
              setIsFocused,
              setSearchQuery,
              setSelectedHostCreditEntityName,
              setValue
            }}
          />
          {isFocused && (
            <div className='relative shadow-navigate-cart border border-[#E5E5EB] rounded-lg mt-1'>
              <div className='overflow-auto max-h-60 credit-entity-dropdown rounded-lg mb-4'>
                <InfiniteHits
                  {...{
                    hitComponent: ({ hit }) => (
                      <Hit
                        {...{
                          dbName,
                          hit,
                          setIsFocused,
                          setSearchQuery,
                          setSelectedHostCreditEntityName,
                          setValue
                        }}
                      />
                    ),
                    showPrevious: false
                  }}
                />
              </div>
              {showAddNewLabel && (
                <label
                  className={`absolute bottom-[0rem] border border-l-0 border-r-0 rounded-b-lg border-[#E5E5EB] text-brand text-sm py-[10px] px-3 bg-white w-full font-medium cursor-pointer`}
                  onClick={onClickAddNewEntity}
                >
                  {addNewLabel}
                </label>
              )}
            </div>
          )}
        </div>
      </InstantSearch>
    </div>
  );
};

export default SearchkitSelectFormInput;
