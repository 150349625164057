import moment from 'moment';
import Image from 'next/image';
import { PropTypes } from 'prop-types';
import DatePickerContainer from 'react-datepicker';

import { staticMediaStoreBaseURL } from '@/config/common';

const DatePicker = ({
  baseClass = 'w-full border border-gray-300 rounded-lg text-sm datepicker-input',
  className,
  dbName,
  errors,
  iconHeight = 18,
  iconURL = 'calendar-gray-with-frame.svg',
  iconWidth = 18,
  minDate,
  selectedDate,
  setSelectedDate,
  setValue
}) => {
  const isInvalidMessage = errors?.[dbName]?.message;
  const calculatedMinDate = minDate ? new Date(minDate) : null;
  const availableSelectedDate = selectedDate ? new Date(selectedDate) : null;

  return (
    <div className='relative'>
      <DatePickerContainer
        calendarClassName=''
        className={`${className} ${baseClass}`}
        minDate={calculatedMinDate}
        onChange={(e) => {
          setValue && setValue(dbName, new Date(e));
          setSelectedDate(e);
        }}
        openToDate={availableSelectedDate || calculatedMinDate}
        placeholderText='MM/DD/YYYY'
        selected={availableSelectedDate}
        showIcon={false}
      />
      <span className='absolute top-3 left-2'>
        <Image
          alt='datepicker'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/${iconURL}`}
          style={{ height: iconHeight, width: iconWidth }}
          width={0}
        />
      </span>
      {isInvalidMessage && (
        <div className='text-xs text-red-500'>{isInvalidMessage}</div>
      )}
    </div>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  baseClass: PropTypes.string,
  className: PropTypes.string,
  dbName: PropTypes.string,
  errors: PropTypes.object,
  minDate: PropTypes.instanceOf(moment),
  selectedDate: PropTypes.instanceOf(moment),
  setSelectedDate: PropTypes.func,
  setValue: PropTypes.func
};
