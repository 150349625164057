import Image from 'next/image';

import { TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const ApplyForCreditBanner = ({
  className = 'bg-white-opacity',
  labelStyle = 'font-semibold text-brand',
  valueStyle = 'font-medium text-brand text-sm'
}) => (
  <div className='flex flex-col'>
    <div
      className={`flex justify-between w-full rounded-xl p-6 my-4 ${className}`}
    >
      <TextKeyValuePair
        {...{
          label: 'Your Credit Entity is Ready!',
          labelClass: labelStyle,
          value: 'Get Started with Credit',
          valueClassName: valueStyle
        }}
      />

      <Image
        alt='Apply for Credit'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/credit-entity-ready-banner.svg`}
        style={{ height: 112, width: 194 }}
        width={0}
      />
    </div>
  </div>
);

export default ApplyForCreditBanner;
