// TODO: Sidd/RJ/TJ Remove the comment code-block, we use single line comments only
/*
axios response object has the following properties
{
  data: <data from the server>,
  status: <HTTP status code>,
  statusText: <HTTP status text>,
  headers: <headers from the server>,
  config: <axios configuration object>
}

1. we are omitting the status, statusText, headers -- SIDD/TJ/RJ  Revisit this - check if we need to incorporate this
2. the data object structure is {entity, message, status} - here in many cases we parse entity and combine with status to see if we need to showToast set/reset data - so here some extraction can be done, now we have 400+ APIs so a good number to evaluate and refactor this

ex:
default isSuccess = (response) => response?.data, and can be overridden if the caller wants to parse the data object as well to evaluate success, can make some common templates like check empty JSON, emptyArray or something
and resolve{data, status, headers, statusText, success }

This is to be done as part of https://www.notion.so/hafla/API-for-illustration-on-how-to-parse-different-resposes-in-FE-cc6710e88ba746258da80db56bbbf3dc?pvs=4
*/

import axios from 'axios';

import { PLANNER_PORTAL_FQDN } from '@/services/connections.service';
import { getAuthToken } from '@/services/identity.service';

axios.defaults.headers = {
  'content-type': 'application/json',
  'x-origin-domain': PLANNER_PORTAL_FQDN
};

export const getWithoutAuth = (url) =>
  new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });

const getOptionsForWithAuth = () => {
  const token = getAuthToken();
  const headers = {
    'x-access-token': token
  };
  return { headers, withCredentials: true };
};

export const getWithAuth = (url) => {
  const withAuthOptions = getOptionsForWithAuth();
  return new Promise((resolve, reject) => {
    axios
      .get(url, withAuthOptions)
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const postWithAuth = (url, entity) => {
  const withAuthOptions = getOptionsForWithAuth();
  return new Promise((resolve, reject) => {
    axios
      .post(url, entity, withAuthOptions)
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const putWithAuth = (url, entity) => {
  const withAuthOptions = getOptionsForWithAuth();
  return new Promise((resolve, reject) => {
    axios
      .put(url, entity, withAuthOptions)
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const patchWithAuth = (url, entity) => {
  const withAuthOptions = getOptionsForWithAuth();
  return new Promise((resolve, reject) => {
    axios
      .patch(url, entity, withAuthOptions)
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const deleteWithAuth = (url, entity) => {
  const withAuthOptions = getOptionsForWithAuth();
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { ...withAuthOptions, data: entity })
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};
