export const REPAYMENT_TERM_DAYS_LIMIT = { MIN: 0, MAX: 365 };

export const CREDIT_REQUEST_STATUS = {
  APPROVED: { className: 'submitted', label: 'Approved', value: 'APPROVED' },
  APPROVED_EXPIRED: {
    className: 'submitted',
    label: 'Approved',
    value: 'APPROVED_EXPIRED'
  },
  REJECTED: { className: 'expired', label: 'Rejected', value: 'REJECTED' },
  REQUESTED: {
    className: 'in-progress',
    label: 'Pending',
    value: 'REQUESTED'
  },
  REQUESTED_EXPIRED: {
    className: 'text-dim-gray bg-neutral',
    label: 'Applied',
    value: 'REQUESTED_EXPIRED'
  }
};

export const REVIEW_CREDIT_REQUEST_CTA_PAIR = {
  APPROVE: {
    label: 'Approve',
    value: CREDIT_REQUEST_STATUS.APPROVED.value
  },
  REJECT: {
    label: 'Reject',
    value: CREDIT_REQUEST_STATUS.REJECTED.value
  }
};

export const PLANNER_CREDIT_FACILITY_REVIEWERS = JSON.parse(
  process.env.NEXT_PUBLIC_CREDIT_FACILITY_REVIEWERS
);
