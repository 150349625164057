import Image from 'next/image';
import { useState } from 'react';

import {
  Button,
  InputField,
  Text,
  TextArea,
  TextKeyValuePair,
  Tooltip
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  CREDIT_REQUEST_STATUS,
  REPAYMENT_TERM_DAYS_LIMIT,
  REVIEW_CREDIT_REQUEST_CTA_PAIR
} from '@/config/hostCreditEntity';
import {
  convertToCurrencyFormat,
  parseFormatPriceNumberValueFromAPI
} from '@/lib/numberStringUtils';
import { isPlannerCreditFacilityReviewer } from '@/services/hostCreditEntity.service';
import { getPlannerName } from '@/services/identity.service';

const ReviewCreditRequestSummary = ({
  isEditable,
  openReviewCreditRequestForm,
  pendingCreditRequest: {
    requestedCreditLimit,
    requester: { name },
    status
  },
  setOpenReviewCreditRequestForm
}) => (
  <div
    className={`flex gap-2 items-center cursor-pointer ${!isEditable ? '!border-brand' : '!border-neutral'} ${openReviewCreditRequestForm ? 'border-neutral rounded-t-lg' : 'rounded-lg border-brand'} border py-3 px-4`}
    onClick={() => setOpenReviewCreditRequestForm(!openReviewCreditRequestForm)}
  >
    <Image
      alt='new credit request'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/notification.svg`}
      style={{ height: 24, width: 24 }}
      width={0}
    />
    <div className='flex gap-1'>
      <TextKeyValuePair
        {...{
          className: 'flex-row',
          label: 'New Request: ',
          labelClass: 'font-medium',
          value: name,
          valueClassName: 'font-medium max-w-28 truncate'
        }}
      />
      <TextKeyValuePair
        {...{
          className: 'flex-row',
          label: 'has applied for a credit facility of',
          labelClass: 'font-light',
          value: convertToCurrencyFormat({
            value: parseFormatPriceNumberValueFromAPI(requestedCreditLimit)
          }),
          valueClassName: 'font-medium'
        }}
      />
    </div>
    <Text
      {...{
        className: `${CREDIT_REQUEST_STATUS[status].className} text-xs font-medium rounded-lg px-2 py-1 ml-auto`,
        content: CREDIT_REQUEST_STATUS[status].label
      }}
    />
    <Image
      alt='open credit request'
      className={`cursor-pointer transform ${openReviewCreditRequestForm ? 'rotate-90' : 'rotate-0'}`}
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/right-angle-red.svg`}
      style={{ height: 16, width: 16 }}
      width={0}
    />
  </div>
);

const ReviewCreditRequestForm = ({
  clearErrors,
  errors,
  isEditable,
  register,
  setValue,
  watch
}) => {
  const selectedReviewActionForCreditRequest = watch(
    'selectedReviewActionForCreditRequest'
  );

  const approveRejectButtonPair = [
    {
      className: `text-nero rounded-lg py-3 font-medium ${selectedReviewActionForCreditRequest === REVIEW_CREDIT_REQUEST_CTA_PAIR.APPROVE.value ? 'border-brand' : ''} border ${isEditable ? 'hover:border-brand hover:text-nero' : ''}  border-[#E5E5EB]`,
      disabled: !isEditable,
      iconHeight: 16,
      iconPosition: 'Left',
      iconURL: `${staticMediaStoreBaseURL}/icons/${selectedReviewActionForCreditRequest === REVIEW_CREDIT_REQUEST_CTA_PAIR.APPROVE.value ? 'check-fill-green-icon.svg' : 'radio.svg'}`,
      iconWidth: 16,
      label: REVIEW_CREDIT_REQUEST_CTA_PAIR.APPROVE.label,
      onClick: () => {
        clearErrors();
        setValue(
          'selectedReviewActionForCreditRequest',
          REVIEW_CREDIT_REQUEST_CTA_PAIR.APPROVE.value
        );
      }
    },
    {
      className: `text-nero rounded-lg py-3 font-medium ${selectedReviewActionForCreditRequest === REVIEW_CREDIT_REQUEST_CTA_PAIR.REJECT.value ? 'border-brand' : ''} border ${isEditable ? 'hover:border-brand hover:text-nero' : ''} border-[#E5E5EB]`,
      disabled: !isEditable,
      iconHeight: 16,
      iconPosition: 'Left',
      iconURL: `${staticMediaStoreBaseURL}/icons/${selectedReviewActionForCreditRequest === REVIEW_CREDIT_REQUEST_CTA_PAIR.REJECT.value ? 'red-cross-fill-icon.svg' : 'radio.svg'}`,
      iconWidth: 16,
      label: REVIEW_CREDIT_REQUEST_CTA_PAIR.REJECT.label,
      onClick: () => {
        clearErrors();
        setValue(
          'selectedReviewActionForCreditRequest',
          REVIEW_CREDIT_REQUEST_CTA_PAIR.REJECT.value
        );
      }
    }
  ];

  const tooltipConfigList = [
    {
      target: 'credit-request-reason-name',
      text: watch('creditRequestReason.name')
    }
  ];

  return (
    <div
      className={`flex flex-col gap-4 rounded-b-lg pt-5 pb-6 px-20 border ${!isEditable ? 'border-l-brand border-b-brand border-r-brand' : 'border-neutral'}`}
    >
      <div className='flex gap-4'>
        <InputField
          {...{
            className: 'rounded-lg pl-10',
            dbName: 'formattedRequestedCreditLimit',
            disabled: true,
            iconURL: 'budget-icon-red.svg',
            inputGroup: 'flex flex-col gap-2 w-1/2',
            label: 'Credit Request Amount *',
            labelClass: 'text-nero text-base font-medium',
            placeholder: 'Enter Credit Amount',
            register: { ...register('formattedRequestedCreditLimit') }
          }}
        />
        <InputField
          {...{
            className: 'rounded-lg pl-10',
            dbName: 'repaymentTermDays',
            disabled: true,
            iconURL: 'trn-icon-red.svg',
            inputGroup: 'flex flex-col gap-2 w-1/2',
            label: 'Payment Terms (in days) *',
            labelClass: 'text-nero text-base font-medium',
            placeholder: `${REPAYMENT_TERM_DAYS_LIMIT.MIN} - ${REPAYMENT_TERM_DAYS_LIMIT.MAX} days (payback terms)`,
            register: { ...register('repaymentTermDays') }
          }}
        />
      </div>
      <InputField
        {...{
          className: 'rounded-lg pl-10',
          dbName: 'creditRequestReason.name',
          disabled: true,
          iconURL: 'trn-icon-red.svg',
          id: 'credit-request-reason-name',
          inputGroup: 'flex flex-col gap-2 w-full',
          label: 'Reason For Credit',
          labelClass: 'text-nero text-base font-medium',
          placeholder: 'Reason',
          register: { ...register('creditRequestReason.name') }
        }}
      />
      {isPlannerCreditFacilityReviewer && (
        <div className='flex gap-4'>
          {approveRejectButtonPair.map(
            (approveRejectButtonPairProps, index) => (
              <Button
                key={index}
                {...approveRejectButtonPairProps}
              />
            )
          )}
        </div>
      )}
      {selectedReviewActionForCreditRequest ===
        REVIEW_CREDIT_REQUEST_CTA_PAIR.APPROVE.value && (
        <div className='flex gap-4'>
          <InputField
            {...{
              className: 'rounded-lg pl-10',
              dbName: 'approvedCreditLimit',
              disabled: !isEditable,
              errors,
              iconURL: 'budget-icon-red.svg',
              inputGroup: 'flex flex-col gap-2 w-full',
              label: 'Maximum Approved Credit *',
              labelClass: 'text-nero text-base font-medium',
              placeholder: 'Approved Credit Amount',
              register: { ...register('approvedCreditLimit') },
              type: 'number'
            }}
          />
        </div>
      )}
      {selectedReviewActionForCreditRequest && (
        <>
          <TextArea
            {...{
              className: 'rounded-lg pl-10',
              dbName: 'reviewerNotes',
              errors,
              inputGroup: 'flex flex-col gap-2 w-full',
              labelClass: 'text-nero text-base font-medium',
              iconURL: 'solution-design-icon-red.svg',
              label: 'Notes',
              placeholder: 'Enter Credit Request Reason Notes',
              register: { ...register('reviewerNotes') }
            }}
          />
          <TextKeyValuePair
            {...{
              className: 'flex-row',
              label: 'Reviewer:',
              labelClass: 'font-medium',
              value: getPlannerName(),
              valueClassName: 'px-1 font-medium'
            }}
          />
        </>
      )}
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

const PendingCreditRequest = ({
  clearErrors,
  errors,
  isEditable,
  pendingCreditRequest,
  register,
  setValue,
  watch
}) => {
  const [openReviewCreditRequestForm, setOpenReviewCreditRequestForm] =
    useState(false);
  return (
    <div>
      <ReviewCreditRequestSummary
        {...{
          isEditable,
          openReviewCreditRequestForm,
          pendingCreditRequest,
          setOpenReviewCreditRequestForm
        }}
      />
      {openReviewCreditRequestForm && (
        <ReviewCreditRequestForm
          {...{
            clearErrors,
            errors,
            isEditable,
            register,
            setValue,
            watch
          }}
        />
      )}
    </div>
  );
};

export default PendingCreditRequest;
