import { RichTextEditor, Text } from '@/components/atomic/nuclei';

const ProductNotes = ({ note }) => (
  <div className='note mb-16 md:mb-0'>
    <Text
      {...{
        content: 'Notes',
        className: 'text-nero text-base md:text-xl font-semibold'
      }}
    />
    <div className='text-xs md:text-sm font-light text-nero flex flex-col gap-1 mt-4'>
      <ul className='list-disc'>
        <RichTextEditor
          {...{
            readOnly: true,
            values: note
          }}
        />
      </ul>
    </div>
  </div>
);

export default ProductNotes;
