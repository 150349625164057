import Image from 'next/image';
import { PropTypes } from 'prop-types';

import {
  Button,
  Text,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import {
  HAFLA_REGISTRATION_DETAILS,
  INVOICE_QUOTE_PDF_TYPE,
  staticMediaStoreBaseURL
} from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';
import { toProperCase } from '@/lib/utils';
import { downloadInvoicePDF } from '@/services/invoiceQuotationCommon.service';
import { getOrderInvoiceAPI } from '@/services/order.service';

const { companyName, formattedAddress, trnNumber } = HAFLA_REGISTRATION_DETAILS;

const downloadInvoice = async ({ orderId, setShowToast }) => {
  const { entity, message, status } = await getOrderInvoiceAPI({ orderId });
  status &&
    downloadInvoicePDF({
      entity,
      isProforma: false
    });
  !status &&
    setShowToast({
      message,
      show: true,
      successToast: status
    });
};

const DownloadInvoicePDF = ({
  isMobile,
  orderId,
  setShowToast,
  showDownloadInvoicePDFButton
}) =>
  showDownloadInvoicePDFButton && (
    <Button
      {...{
        className: `font-medium rounded border border-white self-baseline py-1 ${
          isMobile ? 'w-7 h-7' : ''
        }`,
        iconGroupClassName: 'reduce-gap',
        iconHeight: 20,
        iconPosition: 'Left',
        iconURL: `${staticMediaStoreBaseURL}/icons/download-white-icon.svg`,
        iconWidth: 20,
        label: isMobile ? '' : toProperCase(INVOICE_QUOTE_PDF_TYPE.INVOICE),
        onClick: (e) => {
          e.stopPropagation();
          downloadInvoice({ orderId, setShowToast });
        },
        width: `${isMobile ? '' : 'w-28'}`
      }}
    />
  );

const PlannerUserAndAction = ({
  isMobile,
  orderId,
  plannerUserName,
  setShowToast,
  showDownloadInvoicePDFButton
}) => {
  const tooltipConfigList = [
    {
      target: 'event-planner-name',
      text: 'Event Planner Name'
    }
  ];
  return (
    <div className='hidden md:flex gap-2 justify-end mt-5 mr-2'>
      <div className='w-6 mt-1 flex items-center'>
        <Image
          alt='planner user'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/planner-icon.svg`}
          style={{ height: 22, width: 22 }}
          width={0}
        />
      </div>
      <Text
        {...{
          className: 'self-center text-lg font-medium whitespace-nowrap',
          content: plannerUserName || 'NA',
          id: 'event-planner-name',
          onClick: (e) => e.stopPropagation()
        }}
      />
      <DownloadInvoicePDF
        {...{
          isMobile,
          orderId,
          setShowToast,
          showDownloadInvoicePDFButton
        }}
      />
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

const EventOrderBanner = ({ orderBannerDetail }) => {
  const {
    headerLabel: { class: headerClass, value: headerValue },
    plannerUserName,
    orderId,
    referenceNumber: {
      class: referenceNumberClass,
      label: referenceNumberLabel
    },
    setShowToast
  } = orderBannerDetail;

  const [isMobile] = useIsMobile();

  const showDownloadInvoicePDFButton = false;
  const stopEventPropagation = (e) => e.stopPropagation();

  const tooltipConfigList = [
    {
      target: 'order-number',
      text: 'Order Number'
    }
  ];

  return (
    <div className='flex flex-col md:flex-row justify-between bg-brand-gradient text-white rounded-t-lg p-4 md:p-6'>
      <div
        className='flex flex-col gap-1 md:gap-2'
        onClick={stopEventPropagation}
      >
        <Text
          {...{
            className: 'text-base md:text-lg font-medium',
            content: companyName
          }}
        />
        <Text
          {...{
            className: 'text-xs md:text-sm font-light max-w-80 truncate',
            content: formattedAddress
          }}
        />
        <Text
          {...{
            className: 'text-sm font-light',
            content: `TRN: ${trnNumber}`
          }}
        />
      </div>
      <div className='flex justify-between md:hidden'>
        <Text
          {...{
            className: `flex text-xs md:text-base font-medium text-brand bg-white rounded-full py-1 px-3 md:px-4 mt-2 self-baseline`,
            content: referenceNumberLabel,
            onClick: stopEventPropagation
          }}
        />
        <DownloadInvoicePDF
          {...{
            isMobile,
            orderId,
            setShowToast,
            showDownloadInvoicePDFButton
          }}
        />
      </div>
      <div className='flex flex-col mt-4 md:mt-0'>
        <div className='hidden md:flex gap-4'>
          <Text
            {...{
              className: `${headerClass} text-sm md:text-xl self-center`,
              content: headerValue,
              onClick: stopEventPropagation
            }}
          />
          <TextWithIcon
            {...{
              className: 'py-1 px-2 rounded bg-white',
              icon: 'order-brand-icon.svg',
              iconHeight: 20,
              iconWidth: 20,
              id: 'order-number',
              label: referenceNumberLabel,
              labelStyle: `${referenceNumberClass} self-center text-xs md:text-base font-medium text-dim-gray`,
              onClick: stopEventPropagation
            }}
          />
        </div>

        <div className='flex justify-end md:self-end gap-2'>
          <PlannerUserAndAction
            {...{
              isMobile,
              orderId,
              plannerUserName,
              setShowToast,
              showDownloadInvoicePDFButton
            }}
          />

          <div className='flex justify-between w-full mt-4 self-center items-end text-center'>
            <Text
              {...{
                className:
                  'uppercase font-semibold text-sm md:text-xl self-center block md:hidden',
                content: headerValue,
                onClick: stopEventPropagation
              }}
            />
          </div>
        </div>
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

export default EventOrderBanner;

EventOrderBanner.propTypes = {
  orderBannerDetail: PropTypes.object.isRequired
};
