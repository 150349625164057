import Image from 'next/image';

import { Divider, RichTextEditor, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const TEXT_TYPE = {
  RICH_TEXT: 'Rich Text',
  SIMPLE: 'simple'
};

const isContainsObject = (arr) => arr.some((item) => typeof item === 'object');

const InnerProductSummary = ({ value }) =>
  isContainsObject(value) ? (
    <div>
      {value.map(
        ({ displayName, renderType = null, value: displayValue }, index) =>
          renderType === TEXT_TYPE.RICH_TEXT ? (
            <RichTextEditor
              key={index}
              {...{
                readOnly: true,
                values: displayValue
              }}
            />
          ) : (
            <Text
              key={index}
              {...{
                content: `${displayName} : ${displayValue}`
              }}
            />
          )
      )}
    </div>
  ) : (
    value.join(', ')
  );

const SimpleText = ({ value }) => (
  <div>
    {Array.isArray(value) ? <InnerProductSummary {...{ value }} /> : value}
  </div>
);

const textAttributeMap = {
  [TEXT_TYPE.RICH_TEXT]: RichTextEditor,
  [TEXT_TYPE.SIMPLE]: SimpleText
};

const ProductDetail = ({ displayAttributes }) => (
  <div>
    <div className='product-detail'>
      <Text
        {...{
          className: 'text-nero text-base md:text-xl font-semibold',
          content: 'Product Details'
        }}
      />
      <div className='flex flex-wrap flex-col md:flex-row gap-4 md:gap-0 justify-between mt-4'>
        {displayAttributes.map(({ groupName, renderType, value }, index) => {
          const TextAttribute = textAttributeMap[renderType];

          return (
            <div
              key={index}
              className='flex md:gap-1 w-full md:w-1/2 md:pr-6'
            >
              <div className='md:mt-1 w-6 h-6'>
                <Image
                  {...{
                    alt: 'attribute',
                    height: 0,
                    src: `${staticMediaStoreBaseURL}/icons/star-icon.svg`,
                    style: {
                      height: 16,
                      width: 16
                    },
                    width: 0
                  }}
                />
              </div>
              <div className='flex flex-col gap-1 flex-1 text-xs md:text-sm font-light text-dim-gray md:mt-1'>
                <Text
                  {...{
                    content: groupName,
                    className: 'text-sm font-medium text-nero'
                  }}
                />
                <TextAttribute
                  {...{
                    readOnly: true,
                    value,
                    values: value
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
    <Divider {...{ space: 'mt-8 mb-6' }} />
  </div>
);

export default ProductDetail;
