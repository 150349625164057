import Image from 'next/image';

import {
  TextKeyValuePair,
  TextWithIcon,
  ToggleSwitch,
  Tooltip
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  convertToCurrencyFormat,
  parseFormatPriceNumberValueFromAPI
} from '@/lib/numberStringUtils';
import { convertToShortMonthDateFormat } from '@/lib/time';

const CreditRequestRecordHeader = ({
  createdAt,
  decisionDate,
  isCreditFacilityToggleDisabled,
  register
}) => {
  const tooltipConfigList = [
    {
      target: 'credit-request-date',
      text: 'Credit Request Date'
    },
    {
      target: 'credit-approved-date',
      text: 'Credit Approved Date'
    }
  ];
  return (
    <div className='w-full flex justify-between'>
      <ToggleSwitch
        {...{
          dbName: 'creditFacilityEnabled',
          disabled: isCreditFacilityToggleDisabled,
          label: 'Enable Credit For Use',
          labelStyle: 'text-white font-medium',
          register,
          toggleStyle: 'credit-toggle'
        }}
      />
      <div className='flex gap-4'>
        <TextWithIcon
          {...{
            alt: 'request credit',
            className: 'shadow-card py-1 px-2 rounded bg-white',
            icon: 'wallet-icon-yellow.svg',
            iconHeight: 16,
            iconWidth: 16,
            id: 'credit-request-date',
            label: convertToShortMonthDateFormat(createdAt),
            labelStyle: 'text-xs font-medium text-warning-dark'
          }}
        />
        <TextWithIcon
          {...{
            alt: 'approve credit',
            className: 'shadow-card py-1 px-2 rounded bg-white',
            icon: 'wallet-icon-green.svg',
            iconHeight: 16,
            iconWidth: 16,
            id: 'credit-approved-date',
            label: convertToShortMonthDateFormat(decisionDate),
            labelStyle: 'text-xs font-medium text-green'
          }}
        />
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

const CreditRequestRecordBody = ({
  approvedCreditLimit,
  requestedCreditLimit,
  requester,
  reviewer,
  reviewerNotes
}) => (
  <div>
    <div className='w-full flex justify-between mt-5'>
      <div className='flex items-center gap-3'>
        <Image
          alt='approve credit'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/wallet-icon-white.svg`}
          style={{ height: 44, width: 44 }}
          width={0}
        />
        <TextKeyValuePair
          {...{
            label: `Approved: ${convertToCurrencyFormat({ value: parseFormatPriceNumberValueFromAPI(approvedCreditLimit) })}`,
            labelClass: 'text-white text-lg font-semibold',
            spaceTop: 'mt-0 !gap-0',
            value: `Requested: ${convertToCurrencyFormat({ value: parseFormatPriceNumberValueFromAPI(requestedCreditLimit) })}`,
            valueClassName: 'text-white text-xs font-medium'
          }}
        />
      </div>
      <TextKeyValuePair
        {...{
          label: `Reviewed By: ${reviewer.name}`,
          labelClass: 'text-white text-lg font-semibold',
          spaceTop: 'mt-0 !gap-0',
          value: `Requested By: ${requester.name}`,
          valueClassName: 'text-white text-xs font-medium'
        }}
      />
    </div>
    {reviewerNotes && (
      <TextKeyValuePair
        {...{
          className: 'flex-row gap-2',
          label: 'Reviewer Notes:',
          labelClass: 'text-white text-sm font-medium w-[7.3rem]',
          spaceTop: 'mt-3 !gap-1',
          value: reviewerNotes,
          valueClassName: 'text-white text-sm font-light flex flex-1'
        }}
      />
    )}
  </div>
);

const CreditRequestRecordFooter = ({
  creditRequestReason,
  repaymentTermDays
}) => (
  <div className='flex flex-col gap-2 w-full p-4'>
    <TextKeyValuePair
      {...{
        className: 'flex-row',
        label: 'Payment Terms (in days):',
        labelClass: 'text-dim-gray text-sm font-medium',
        value: repaymentTermDays,
        valueClassName: 'text-dim-gray text-sm'
      }}
    />
    <TextKeyValuePair
      {...{
        className: 'flex-row',
        label: 'Reason For Credit:',
        labelClass: 'text-dim-gray text-sm font-medium w-[8.4rem]',
        value: creditRequestReason.name,
        valueClassName: 'text-dim-gray text-sm flex flex-1'
      }}
    />
  </div>
);

const ApprovedCreditRequest = ({
  approvedCreditRequest: {
    approvedCreditLimit,
    createdAt,
    creditRequestReason,
    decisionDate,
    repaymentTermDays,
    requestedCreditLimit,
    requester,
    reviewer,
    reviewerNotes
  },
  isCreditFacilityToggleDisabled,
  register
}) => (
  <div className='flex flex-col rounded-lg border border-[#F3F3F6] shadow-navigate-cart mb-20'>
    <div className='bg-brand-gradient text-white rounded-t-lg p-4'>
      <CreditRequestRecordHeader
        {...{
          createdAt,
          decisionDate,
          isCreditFacilityToggleDisabled,
          register
        }}
      />
      <CreditRequestRecordBody
        {...{
          approvedCreditLimit,
          requestedCreditLimit,
          requester,
          reviewer,
          reviewerNotes
        }}
      />
    </div>
    <CreditRequestRecordFooter
      {...{ creditRequestReason, repaymentTermDays }}
    />
  </div>
);

export default ApprovedCreditRequest;
