import ReactProgressBar from '@ramonak/react-progress-bar';

const ProgressBar = ({ progressBarPercentage }) => {
  const normalizedPercentage = Math.min(Math.max(progressBarPercentage, 0), 99);
  return (
    <div className='w-full event-profile-progress-bar'>
      <ReactProgressBar
        {...{
          bgColor: '#222222',
          completed: normalizedPercentage,
          customLabel: `${progressBarPercentage}%`,
          customLabelStyles: { margin: normalizedPercentage ? 8 : 0 },
          labelAlignment: normalizedPercentage ? 'right' : 'left',
          labelColor: normalizedPercentage ? '#fff' : '#EA4842',
          labelSize: '12.8px'
        }}
      />
    </div>
  );
};

export default ProgressBar;
