import { Tooltip as ReactstrapTooltip } from 'reactstrap';

import { TOOLTIP_DELAY_TIME } from '@/config/common';
import useTooltip from '@/hooks/useTooltip';

const Tooltip = ({ configList }) => {
  const { tooltip, toggleTooltip } = useTooltip();

  return configList.map(({ target, text }) => (
    <ReactstrapTooltip
      delay={{ show: TOOLTIP_DELAY_TIME }}
      innerClassName='bg-black text-white text-xs p-1.5 px-2 rounded mt-1 mr-3 z-50'
      isOpen={tooltip[target] || false}
      key={target}
      placement='bottom'
      target={target}
      toggle={() => toggleTooltip(target)}
    >
      {text}
    </ReactstrapTooltip>
  ));
};

export default Tooltip;
