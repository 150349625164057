import { InputField, SelectFormInput } from '@/components/atomic/nuclei';
import { hostCreditEntityProfileTabs } from '@/services/hostCreditEntity.service';

const HostCreditEntityProfileStepOne = ({
  clearErrors,
  errors,
  formActiveStepId,
  getValues,
  isEditable,
  opsUserList,
  register,
  setValue
}) => {
  const formStepVisibilityClass =
    formActiveStepId === hostCreditEntityProfileTabs.PROFILE.id
      ? 'block'
      : 'hidden';
  return (
    <div className={formStepVisibilityClass}>
      <div className='bg-white rounded-lg px-6 pb-4 flex flex-col gap-4'>
        <InputField
          {...{
            className: 'rounded-lg pl-10 !py-4',
            dbName: 'name',
            disabled: true,
            errors,
            iconURL: isEditable
              ? 'host-credit-entity-red-icon.svg'
              : 'host-credit-entity-black-icon.svg',
            inputGroup: 'flex flex-col gap-2',
            label: 'Entity Name',
            labelClass: 'text-nero text-base font-medium',
            placeholder: 'Enter Entity Name',
            register: {
              ...register('name')
            }
          }}
        />
        <SelectFormInput
          {...{
            clearErrors,
            containerClassName:
              'relative multi-select-epm epm-dropdown border border-platinum rounded',
            dbName: 'accountManager.id',
            disabled: true,
            errors,
            getValues,
            icon: {
              url: isEditable
                ? 'planner-icon-orange.svg'
                : 'planner-black-icon.svg'
            },
            isClearable: false,
            label: 'Account Manager',
            name: 'Account Manager',
            placeholderLabel: 'Select Account Manager',
            register,
            selectContainerClassName:
              'epm-dropdown border border-platinum rounded !text-base',
            setValue,
            showIcon: true,
            showLabel: true,
            values: opsUserList
          }}
        />
      </div>
    </div>
  );
};

export default HostCreditEntityProfileStepOne;
