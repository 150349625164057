import Image from 'next/image';
import { useRouter } from 'next/router';
import { isMobile } from 'react-device-detect';

import { Button, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { ADMIN_GOOGLE_LOGIN_API_URL } from '@/services/url.service';

const LoginCTA = () => {
  const router = useRouter();
  const redirectToSSO = () => {
    router.push(ADMIN_GOOGLE_LOGIN_API_URL());
  };
  return (
    <div
      className='flex justify-center text-base font-medium'
      onClick={redirectToSSO}
    >
      <Button
        {...{
          className: `bg-white rounded-full text-nero px-8 py-3 product-more-detail-btn hover:bg-brand-gradient hover:text-white hover:border-white hover:border`,
          iconGroupClassName: 'gap-2',
          iconHeight: 20,
          iconPosition: 'Left',
          iconURL: `${staticMediaStoreBaseURL}/icons/google-icon.svg`,
          iconWidth: 20,
          label: 'Sign In with Google',
          width: 'w-auto'
        }}
      />
    </div>
  );
};

const Welcome = ({ plannerName }) => (
  <div className='bg-brand-gradient h-screen overflow-hidden'>
    <div className='flex my-10 md:my-16 flex-col justify-center'>
      <div className='item-center text-center text-white mt-14'>
        {plannerName && (
          <Text
            {...{
              className: 'font-medium text-2xl mb-4',
              content: `Welcome ${plannerName}`,
              suppressHydrationWarning: true
            }}
          />
        )}
        <Text
          {...{
            className: 'text-3xl md:text-4xl font-semibold',
            content: 'Event Planning Concierge'
          }}
        />
        <div className={`my-10 md:my-16 mx-auto flex justify-center`}>
          <Image
            alt='splash'
            height={0}
            priority={true}
            src={`${staticMediaStoreBaseURL}/icons/splash-screen-icon.svg`}
            style={{
              height: isMobile ? 226 : 374,
              width: isMobile ? 266 : 440
            }}
            width={0}
          />
        </div>
        {!plannerName && <LoginCTA />}
      </div>
    </div>
  </div>
);

export default Welcome;
