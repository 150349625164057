import * as HttpService from '@/services/http.service';
import {
  ADD_TO_CART_API_URL,
  GET_PRODUCT_API_URL,
  VERIFY_CART_NUMBER_FOR_ADD_ITEM_API_URL
} from '@/services/url.service';

const cartNumberVerificationStatuses = {
  FAILED: 'FAILED',
  UNCHECKED: 'UNCHECKED',
  VERIFIED: 'VERIFIED'
};

const getProductAPI = ({ productSlug }) =>
  HttpService.getWithoutAuth(GET_PRODUCT_API_URL({ productSlug }));

const verifyCartNumberForAddItemAPI = ({ cartNumber }) =>
  HttpService.getWithAuth(
    VERIFY_CART_NUMBER_FOR_ADD_ITEM_API_URL({ cartNumber })
  );

const addToCartAPI = ({ cartId, userCartId, data }) =>
  HttpService.postWithAuth(ADD_TO_CART_API_URL({ cartId, userCartId }), data);

export const addToCart = async ({
  activePlannerCart,
  productDetail,
  setShowToast,
  updateActivePlannerCart
}) => {
  const { id: cartId, userCartId } = activePlannerCart;
  const { entity, message, status } = await addToCartAPI({
    cartId,
    userCartId,
    data: { ...productDetail, userCartId }
  });
  if (status && entity.id) {
    updateActivePlannerCart({
      ...activePlannerCart,
      cartItemCount: entity.cartItemCount
    });
  }
  setShowToast({
    message,
    show: true,
    successToast: status
  });
};

export const addToCartFromSelectCartModal = async ({
  closeActionModal,
  productDetail,
  setActiveModal = () => {},
  setActiveModalProps,
  setInvalidMessage: setInvalidCartNumberMessage,
  updateActivePlannerCart,
  verifiedCartDetail
}) => {
  const { id: cartId, userCartId } = verifiedCartDetail;
  const { entity, message, status } = await addToCartAPI({
    cartId,
    userCartId,
    data: { ...productDetail, userCartId }
  });
  if (status && entity.id) {
    updateActivePlannerCart({
      ...verifiedCartDetail,
      cartItemCount: entity.cartItemCount
    });
    closeActionModal();
    setActiveModal(setActiveModalProps);
  } else {
    setInvalidCartNumberMessage(message);
  }
};

export const verifyCartNumberForAddItem = async ({
  inputValue: cartNumber,
  setInvalidMessage: setInvalidCartNumberMessage,
  setTargetEntity: setVerifiedCartDetail,
  setVerificationStatus: setCartVerificationStatus
}) => {
  const { FAILED, VERIFIED } = cartNumberVerificationStatuses;
  const { status, entity } = await verifyCartNumberForAddItemAPI({
    cartNumber
  });
  if (status && entity.isVerified) {
    setVerifiedCartDetail({ ...entity, cartNumber });
    setCartVerificationStatus(VERIFIED);
  } else {
    const { error: { errorMessage } = {} } = entity;
    const apiErrorMessage = errorMessage
      ? 'Cart status Invalid'
      : 'Cart number Invalid.';
    setCartVerificationStatus(FAILED);
    setInvalidCartNumberMessage(apiErrorMessage);
  }
};

export const fetchProductDetail = async ({
  productSlug,
  setLoading,
  setProduct,
  setShowToast
}) => {
  setLoading(true);

  const { entity, message, status } = await getProductAPI({
    productSlug
  });
  if (status) {
    setProduct(entity);
  }
  setShowToast({
    message,
    show: !status,
    successToast: status
  });
  setLoading(false);
};
